import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FiInfo } from 'react-icons/fi';

const NewProject = ({ user_id }) => {
  const [projectName, setProjectName] = useState('');
  const [creationName, setCreationName] = useState('');
  const [message, setMessage] = useState('');
  const navigate = useNavigate();
  const [showProjectInfo, setShowProjectInfo] = useState(false);
  const [showCreationInfo, setShowCreationInfo] = useState(false);

  const toggleProjectInfo = () => setShowProjectInfo((prev) => !prev);
  const toggleCreationInfo = () => setShowCreationInfo((prev) => !prev);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const payload = {
      user_id,
      project_name: projectName,
      creation_name: creationName,
    };

    try {
      const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || '';

      const response = await fetch(`${API_BASE_URL}/create_project/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
      });

      const data = await response.json();
      if (data.status === 'success') {
        setMessage('Project created successfully!');
        navigate('/newproject2', {
          state: {
            user_id: user_id,
            creation_id: data.data.creation_id,
            video_name: data.data.creation_name,
            project_id: data.data.project_id
          }
        });
      } else {
        setMessage('Failed to create project.');
      }
    } catch (error) {
      console.error('Error:', error);
      setMessage('An error occurred while creating the project.');
    }

    setProjectName('');
    setCreationName('');
  };

  return (
    <div className="flex items-center justify-center h-[80vh] bg-transparent m-0">
      <form onSubmit={handleSubmit} className="bg-gray-800 rounded-lg shadow-lg p-6 w-full max-w-md">
        <h2 className="text-2xl font-bold text-center text-gray-300 mb-4">Create New Campaign</h2>

        {message && <p className="text-center text-gray-300 mb-4">{message}</p>}

        <div className="mb-4">
          <div className="flex items-center gap-2 mb-1">
            <label className="text-gray-400 text-xl" htmlFor="projectName">
              Campaign Name
            </label>
            <button
              type="button"
              onClick={toggleProjectInfo}
              className="text-white hover:bg-indigo-700 transition duration-300 focus:outline-none rounded-full w-6 h-6 flex items-center justify-center"
            >
              <FiInfo size={18} />
            </button>
          </div>

          {showProjectInfo  && (
            <div className="absolute bg-gray-700 text-white text-sm rounded-lg p-3 shadow-md w-80 mt-2 z-50">
              <p> This name represents a project or larger group of videos. e.g Nightwear, Resorts, Arcade Games, Shoes, etc depending upon the category that is relevant to you. A Project can have many creations (Videos) associated with them. You can group similar creations into 1 project/campaign.</p>
            </div>
          )}

          <input
            type="text"
            id="projectName"
            value={projectName}
            onChange={(e) => setProjectName(e.target.value)}
            required
            className="w-full p-2 rounded bg-gray-700 text-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-400"
          />
        </div>

        <div className="mb-4">
          <div className="flex items-center gap-2 mb-1">
            <label className="block text-gray-400 mb-1 text-xl" htmlFor="creationName">
              Creation Name
            </label>

            <button
              type="button"
              onClick={toggleCreationInfo}
              className="text-white hover:bg-indigo-700 transition duration-300 focus:outline-none rounded-full w-6 h-6 flex items-center justify-center"
            >
              <FiInfo size={18} />
            </button>
          </div>

          {showCreationInfo  && (
            <div className="absolute bg-gray-700 text-white text-sm rounded-lg p-3 shadow-md w-80 mt-2 z-50">
              <p> A creation represents a unique video content group. It includes videos of a single image source, with video outputs inlcuding a raw video, a processed video and a premium video. 1 project can have many creations. 1 Creation will have many videos but all from the same source image.</p>
            </div>
          )}

          <input
            type="text"
            id="creationName"
            value={creationName}
            onChange={(e) => setCreationName(e.target.value)}
            required
            className="w-full p-2 rounded bg-gray-700 text-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-400"
          />
        </div>

        <button
          type="submit"
          className="w-full p-2 bg-indigo-500 text-white rounded hover:bg-indigo-600 transition duration-300"
        >
          Create
        </button>
      </form>
    </div>
  );
};

export default NewProject;