import React, { useState, useEffect } from 'react';

const Assets = ({ user_id }) => {
  const [assets, setAssets] = useState([]);
  const [error, setError] = useState('');
  const [pagination, setPagination] = useState({});
  const [currentPage, setCurrentPage] = useState(1); // Track the current page

  useEffect(() => {
    const fetchAssets = async () => {
      try {
        const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || '';

        const response = await fetch(`${API_BASE_URL}/assets_page?user_id=${user_id}&page=${currentPage}`);
        const data = await response.json();

        if (data.status === 'success') {
          setAssets(data.data); // Set the assets data
          setPagination(data.pagination); // Set pagination data
        } else {
          setError(data.message || 'Failed to fetch assets');
        }
      } catch (err) {
        console.error('Error fetching assets:', err);
        setError('An error occurred while fetching the assets.');
      }
    };

    fetchAssets();
  }, [user_id, currentPage]);

  const goToPreviousPage = () => {
    if (currentPage > 1) setCurrentPage(currentPage - 1);
  };

  const goToNextPage = () => {
    if (currentPage < pagination.pages) setCurrentPage(currentPage + 1);
  };

  const renderImageUrls = (imageUrls) => {
    // If imageUrls is an array, render it
    if (Array.isArray(imageUrls)) {
      return imageUrls.length > 0 ? (
        imageUrls.map((url, index) => (
          <p key={index} className="text-indigo-400">
            <a
              href={url}
              target="_blank"
              rel="noopener noreferrer"
              className="hover:text-indigo-600"
              title={url} // Show the URL on hover as a tooltip
            >
              Image URL
            </a>
          </p>
        ))
      ) : (
        'No Images'
      );
    }

    // If imageUrls is an object with cdn_urls, render the cdn_urls array
    if (imageUrls && imageUrls.cdn_urls && Array.isArray(imageUrls.cdn_urls)) {
      return imageUrls.cdn_urls.length > 0 ? (
        imageUrls.cdn_urls.map((url, index) => (
          <p key={index} className="text-indigo-400">
            <a
              href={url}
              target="_blank"
              rel="noopener noreferrer"
              className="hover:text-indigo-600"
              title={url} // Show the URL on hover as a tooltip
            >
              Image URL
            </a>
          </p>
        ))
      ) : (
        'No Images'
      );
    }

    // If neither condition is met, return 'No Images'
    return 'No Images';
  };

  return (
    <div className="p-5 bg-transparent min-h-screen z-10">
      <h1 className="text-3xl font-bold text-center text-gray-300 mb-6">Assets</h1>

      {error && <p className="text-red-500 text-center">{error}</p>}

      <div className="bg-gray-100 shadow-md rounded-lg p-6 bg-transparent text-gray-300 border border-gray-600">
        {assets.length > 0 ? (
          <table className="w-full text-left bg-transparent">
            <thead>
              <tr>
                <th className="py-3 px-4 border-b border-gray-600 text-gray-300 font-semibold">Creation ID</th>
                <th className="py-3 px-4 border-b border-gray-600 text-gray-300 font-semibold">Product Name</th>
                <th className="py-3 px-4 border-b border-gray-600 text-gray-300 font-semibold">Dimensions</th>
                <th className="py-3 px-4 border-b border-gray-600 text-gray-300 font-semibold">Video Status</th>
                <th className="py-3 px-4 border-b border-gray-600 text-gray-300 font-semibold">Images</th>
                <th className="py-3 px-4 border-b border-gray-600 text-gray-300 font-semibold">Created At</th>
              </tr>
            </thead>
            <tbody>
              {assets.map((asset) => (
                <tr key={asset.creation_id} className="hover:bg-gray-700">
                  <td className="py-3 px-4 border-b border-gray-600">{asset.creation_id}</td>
                  <td className="py-3 px-4 border-b border-gray-600">{asset.product_name || 'N/A'}</td>
                  <td className="py-3 px-4 border-b border-gray-600">{asset.video_dimensions || 'N/A'}</td>
                  <td className="py-3 px-4 border-b border-gray-600">{asset.status}</td>
                  <td className="py-3 px-4 border-b border-gray-600">{renderImageUrls(asset.image_urls)}</td>
                  <td className="py-3 px-4 border-b border-gray-600">
                    {asset.created_at}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <p className="text-center text-gray-300">No assets found.</p>
        )}
      </div>

      {/* Pagination Controls */}
      {pagination && (
        <div className="flex justify-center items-center mt-6 text-gray-300 space-x-4">
          <button
            onClick={goToPreviousPage}
            disabled={currentPage === 1}
            className={`px-4 py-2 rounded ${currentPage === 1 ? 'opacity-50 cursor-not-allowed' : 'hover:bg-gray-600'}`}
          >
            &larr; Previous
          </button>
          <p>Page {pagination.page} of {pagination.pages}</p>
          <button
            onClick={goToNextPage}
            disabled={currentPage === pagination.pages}
            className={`px-4 py-2 rounded ${currentPage === pagination.pages ? 'opacity-50 cursor-not-allowed' : 'hover:bg-gray-600'}`}
          >
            Next &rarr;
          </button>
        </div>
      )}
    </div>
  );
};

export default Assets;
