import React, { useEffect, useState } from 'react';
import { FiInfo } from 'react-icons/fi';


function AlertBox({ message, onClose, onConfirm }) {
  return (
    <div className="fixed inset-0 flex items-center justify-center z-50 bg-gray-900 bg-opacity-75">
      <div className="bg-gray-800 border border-indigo-500 rounded-lg p-5 text-center shadow-lg w-[80%] sm:w-[50%]">
        <p className="text-white text-lg">{message}</p>
        <div className="mt-4 flex justify-center space-x-4">
          <button
            onClick={onConfirm}
            className="px-4 py-2 bg-indigo-600 text-white rounded-lg hover:bg-indigo-700 focus:outline-none"
          >
            Done
          </button>
          <button
            onClick={onClose}
            className="px-4 py-2 bg-gray-600 text-white rounded-lg hover:bg-gray-700 focus:outline-none"
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
}

const GenerateKey = ({ user_id }) => {
  const [keyData, setKeyData] = useState(null);
  const [isGenerating, setIsGenerating] = useState(false);
  const [generatedKey, setGeneratedKey] = useState(null);
  const [error, setError] = useState(null);
  const [keyName, setKeyName] = useState('');
  const [showKeyNameInput, setShowKeyNameInput] = useState(false);
  const [showGeneratedKey, setShowGeneratedKey] = useState(false);
  const [keyInput, setKeyInput] = useState('');
  const [alertMessage, setAlertMessage] = useState('');
  const [keyToDelete, setKeyToDelete] = useState(null); // Track the key to delete
  const [showInfo, setShowInfo] = useState(false); // Toggle for info tooltip
  const [isCopied, setIsCopied] = useState(false);


  const toggleInfo = () => setShowInfo(!showInfo);

  // Fetch key data on component mount
  useEffect(() => {
    const fetchKeyData = async () => {
      try {
        const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || '';

        const response = await fetch(`${API_BASE_URL}/list_keys?user_id=${user_id}`);
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data = await response.json();
        setKeyData(data);
      } catch (error) {
        setError('Error fetching key data. Please try again later.');
        console.error("Error fetching key data:", error);
      }
    };

    fetchKeyData();
  }, [user_id]);

  const handleKeyNameChange = (e) => {
    setKeyName(e.target.value);
  };

  const generateNewKey = async () => {
    if (!keyName) {
      setAlertMessage('Please enter a key name.');
      return;
    }

    setIsGenerating(true);
    setError(null);

    try {
      const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || '';

      const response = await fetch(`${API_BASE_URL}/generate_key/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          key_name: keyName,
          user_id: user_id,
        }),
      });

      const result = await response.json();

      if (result.status === 'success') {
        setGeneratedKey(result.data);
        setError(null);
        setShowGeneratedKey(true); // Show generated key after successful creation
        setShowKeyNameInput(false); // Hide the key name input field
      } else {
        setAlertMessage(result.message || 'Something went wrong');
      }
    } catch (error) {
      setAlertMessage('Error generating key. Please try again later.');
    } finally {
      setIsGenerating(false);
    }
  };

  const handleGenerateButtonClick = () => {
    setShowKeyNameInput(true); // Show the key name input when the button is clicked
  };

  // Handle deletion
  const deleteKey = (keyId) => {
    setKeyToDelete(keyId); // Set the key ID to be deleted
    setAlertMessage('Are you sure you want to delete this key?'); // Set the message for the alert box
  };

  const confirmDeletion = async () => {
    if (keyToDelete) {
      try {
        const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || '';
        const response = await fetch(`${API_BASE_URL}/delete_api_key/`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            key_id: keyToDelete,
          }),
        });

        const result = await response.json();

        if (result.status === 'success') {
          setKeyData((prevData) => ({
            ...prevData,
            data: {
              ...prevData.data,
              keys: prevData.data.keys.filter((key) => key.key_id !== keyToDelete),
            },
          }));
          setError(null);
        } else {
          setError(result.message || 'Error deleting the key');
        }
      } catch (error) {
        setError('Error deleting key. Please try again later.');
      }
    }
    setAlertMessage(''); // Close the alert box after confirmation
  };

  const cancelDeletion = () => {
    setAlertMessage(''); // Close the alert box if canceled
    setKeyToDelete(null);
  };
  // Fetch key data function (no changes here)
const fetchKeyData = async () => {
  try {
    const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || '';

    const response = await fetch(`${API_BASE_URL}/list_keys?user_id=${user_id}`);
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    const data = await response.json();
    setKeyData(data);
  } catch (error) {
    setError('Error fetching key data. Please try again later.');
    console.error("Error fetching key data:", error);
  }
};


  const handleNextClick = () => {
    generateNewKey(); // Call the function to generate the new key when the "Next" button is clicked
  };

  const copyToClipboard = () => {
    if (generatedKey?.api_key) {
      navigator.clipboard.writeText(generatedKey.api_key)
        .then(() => {
          setIsCopied(true); // Change button text to "Copied!"
          setTimeout(() => setIsCopied(false), 2000); // Revert back after 2 seconds
        })
        .catch((err) => {
          setAlertMessage('Failed to copy API Key: ' + err);
        });
    }
  };
  

  if (!keyData) {
    return <div className="text-center text-gray-300 mt-10">Loading...</div>;
  }

  return (
    <div className="p-5 bg-transparent min-h-screen z-10">
      {alertMessage && (
        <AlertBox
          message={alertMessage}
          onClose={cancelDeletion}  // Close the alert box on Cancel
          onConfirm={confirmDeletion} // Confirm deletion on "Confirm"
        />
      )}

      <h2 className="text-2xl font-semibold text-center text-gray-300 mb-6">Manage API Keys</h2>

      {/* Button to generate a new API key */}
      <div className="text-center mb-5">
        <button
          onClick={handleGenerateButtonClick}
          className="bg-indigo-600 text-white p-3 rounded-lg shadow-md hover:bg-indigo-700 transition duration-300"
          disabled={isGenerating}
        >
          {isGenerating ? 'Generating...' : 'Generate New Key'}
        </button>
      </div>

      {/* Show key name input field when the button is clicked */}
      {showKeyNameInput && (
  <div className="mb-5 text-center flex items-center justify-center gap-2 relative">
    {/* Key Name Input */}
    <input
      type="text"
      value={keyName}
      onChange={handleKeyNameChange}
      placeholder="Enter Key Name"
      className="p-3 rounded-lg text-black bg-gray-200 w-80"
    />
    
    {/* Info Button */}
    <button
      onClick={toggleInfo}
      className="flex items-center justify-center  text-white rounded-full w-8 h-8 hover:bg-indigo-700 transition duration-300 focus:outline-none"
    >
      <FiInfo size={20} />
    </button>
    
    {/* Info Tooltip */}
    {showInfo && (
      <div className="absolute bg-gray-700 text-white text-sm rounded-lg p-3 shadow-md w-64 top-12 left-1/2 transform -translate-x-1/2 z-50">
        <p>
          <strong>Enter a Name for Your Key:</strong> This is to easily identify your cryptic key in the future. Enter something that relates to a project or task.
        </p>
      </div>
    )}
  </div>
)}

      {/* Conditional rendering of key name input or generated key details */}
      {showKeyNameInput && (
        <div className="text-center mb-5">
          {/* Buttons for Next and Cancel */}
          <div className="inline-flex space-x-4">
            {/* Next button */}
            <button
              onClick={handleNextClick}
              className="bg-indigo-600 text-white p-3 rounded-lg shadow-md hover:bg-indigo-700 transition duration-300"
              disabled={isGenerating}
            >
              {isGenerating ? 'Generating...' : 'Next'}
            </button>

            {/* Cancel button */}
            <button
              onClick={() => {
                setShowKeyNameInput(false);  // Hide key name input
                setKeyName('');  // Clear the key name input
                setError(null);  // Reset any error message
              }}
              className="bg-gray-600 text-white p-3 rounded-lg shadow-md hover:bg-gray-700 transition duration-300"
            >
              Cancel
            </button>
          </div>
        </div>
      )}

      {/* Show generated key */}
      {showGeneratedKey && generatedKey && (
        <div className="text-center mt-6">
          <p className="text-gray-300 mb-4">
            Here is your key. Please copy it and save it somewhere secure.You won't be able to see this key contents again.

          </p>

          <input
            type="text"
            value={generatedKey?.api_key || keyInput}
            onChange={(e) => setKeyInput(e.target.value)}
            placeholder="Generated Key"
            className="p-3 rounded-lg text-black bg-gray-200 mb-4 w-80 text-center mx-auto"
            readOnly
          />

<button
  onClick={copyToClipboard}
  className={`${
    isCopied ? 'bg-green-600' : 'bg-blue-600'
  } text-white p-3 rounded-lg shadow-md hover:${
    isCopied ? 'bg-green-700' : 'bg-blue-700'
  } transition duration-300`}
>
  {isCopied ? 'Copied!' : 'Copy'}
</button>


          <button
            onClick={() => {
              setShowGeneratedKey(false);
              setKeyInput('');
              fetchKeyData(); // Re-fetch the list of keys
            }}
            className="bg-green-600 text-white p-3 rounded-lg shadow-md hover:bg-green-700 transition duration-300"
          >
            Done
          </button>
        </div>
      )}

      {/* Error message */}
      {error && <div className="text-red-500 text-center mb-5">{error}</div>}

      {/* Display the list of existing keys */}
      <div className="bg-gray-800 shadow-lg rounded-lg p-5 mt-6 text-gray-300 border border-gray-600 overflow-x-auto">
        <table className="w-full text-left">
          <thead>
            <tr>
              <th className="py-2 px-4 border-b border-gray-600">Key ID</th>
              <th className="py-2 px-4 border-b border-gray-600">Key Name</th>
              <th className="py-2 px-4 border-b border-gray-600">Masked Key</th>
              <th className="py-2 px-4 border-b border-gray-600">Created At</th>
              <th className="py-2 px-4 border-b border-gray-600">Last Used</th>
              <th className="py-2 px-4 border-b border-gray-600">Actions</th>
            </tr>
          </thead>
          <tbody>
            {keyData?.data?.keys?.map((key) => (
              <tr key={key.key_id} className="hover:bg-gray-700">
                <td className="py-2 px-4 border-b border-gray-600">{key.key_id}</td>
                <td className="py-2 px-4 border-b border-gray-600">{key.key_name}</td>
                <td className="py-2 px-4 border-b border-gray-600">
                  {key.api_key ? key.api_key.substring(0, 10) + '...' : '**********'}
                </td>
                <td className="py-2 px-4 border-b border-gray-600">{key.created_at}</td>
                <td className="py-2 px-4 border-b border-gray-600">
                  {key.last_used ? key.last_used : 'Never used'}
                </td>
                <td className="py-2 px-4 border-b border-gray-600">
                  <button
                    onClick={() => deleteKey(key.key_id)}
                    className="bg-red-600 text-white p-2 rounded-lg shadow-md hover:bg-red-700 transition duration-300"
                  >
                    Delete
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default GenerateKey;
