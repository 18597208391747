import React from "react";
import { Link } from "react-router-dom";
import { useState } from 'react';
import { useEffect } from 'react';

const Logo = () => {
  const [showStaticImage, setShowStaticImage] = useState(false);
  const gifDuration = 1150;

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowStaticImage(true);
    }, gifDuration);

    return () => clearTimeout(timer);
  }, []);

  return (
    <Link to="/" aria-label="HyGaar" style={{ display: 'inline-block' }}>
      {showStaticImage ? (
        <img
          src={`${process.env.PUBLIC_URL}/logo.svg`}
          alt="Logo"
          width={80}
        />
      ) : (
        <img
          src={`${process.env.PUBLIC_URL}/logo.gif`}
          alt="Logo"
          width={80}
        />
      )}
    </Link>
  );
};

export default Logo;