import React, { useState, useEffect, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

function AlertBox({ message, onClose }) {
  return (
    <div className="fixed inset-0 flex items-center justify-center z-10  bg-opacity-10">
      <div className="relative bg-gray-800 border border-indigo-500 rounded-lg p-2 text-center shadow-xl max-w-6xl">
        <button
          onClick={onClose}
          className="absolute top-2 right-2 text-gray-400 hover:text-white transition"
          aria-label="Close"
        >
          ✕
        </button>
        <div className="text-white text-lg">{message}</div>
      </div>
    </div>
  );
}

const ExecuteVideo = () => {
  const [loading, setLoading] = useState(false);
  const [response, setResponse] = useState(null);
  const [selectedKey, setSelectedKey] = useState('No Key Provided');
  const [alertMessage, setAlertMessage] = useState('');
  const location = useLocation();
  const navigate = useNavigate();

  const buttonRef = useRef(null);

  // Destructuring the props passed from previous page
  const {
    updatedCreation: {
      consumer_message = 'No Consumer Message Provided',
      context = 'No Context Provided',
      duration = 'No Duration Provided',
      goal = 'No Goal Provided',
      image_urls = [],
      key_instructions = 'No Key Instructions Provided',
      layover_text = 'No Layover Text Provided',
      product_name = 'No Product Name Provided',
      sector = 'No Sector Provided',
      tags = [],
      video_dimensions = 'No Dimensions Provided',
      video_name = 'No Video Name Provided',
      project_id = 'No Project ID Provided',
      creation_id = 'No Creation ID Provided',
    } = {},
    selectedKey: locationSelectedKey = 'No Key Provided',
   
  } = location.state || {};

  useEffect(() => {
    if (locationSelectedKey !== 'No Key Provided') {
      setSelectedKey(locationSelectedKey);
    }

    if (buttonRef.current) {
      buttonRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  }, [locationSelectedKey]);

  const handleVideoGeneration = async () => {
    if (!selectedKey || selectedKey === 'No Key Provided') {
      alert('Error: Selected Key is missing.');
      return;
    }

    setLoading(true);
    const payload = {
      key_id: selectedKey,
      project_id,          // Added project_id
      creation_id,         // Added creation_id
      image_urls,
      video_dimensions,
      duration: parseInt(duration) || 10,
      sector,
      goal,
      key_instructions,
      product_name,
      project_name: video_name,
      tags,
      context,
      layover_text,
      consumer_message,
    };

    console.log('Sending Payload:', payload);

    try {
      const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || '';
      const res = await fetch(`${API_BASE_URL}/execute_for_video/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
      });

      const data = await res.json();

      if (res.ok) {
        setAlertMessage(
          <>
            <h2 class="text-lg font-bold text-gray-200 mb-4">🎉 Congratulations!</h2>
            <p class="text-indigo-400 mb-4">
              Your video generation has begun as per your inputs. It should take anywhere between 
              <span class="font-semibold">30 seconds to 10 minutes</span> to generate the video. 
              You can check the status of your video generation using the above URL.
            </p>
            <p class="text-indigo-400 mb-4">
              You will receive <span class="font-semibold">3 Videos</span>:
            </p>
            <ul class="list-disc list-inside text-gray-200 mb-4">
              <li>A <span class="font-semibold">Clean video</span> with the subject/product in focus.</li>
              <li>A <span class="font-semibold">processed video</span> (if you opted for layover text).</li>
              <li>A <span class="font-semibold">Premium Layered Video</span> with enhanced post-processing, logo, and branding generated from our automation system.</li>
            </ul>
            <p class="text-indigo-400 mb-4">
              After each video generation is complete, you will receive an email notification with a link to view and download the videos.
            </p>
            <p class="text-indigo-400 mb-4">
              You can also see these videos and the images used in making them in your <span class="font-semibold">Dashboard</span> after login.
            </p>
            <p class="text-indigo-400 italic">
              <span class="font-semibold">NOTE:</span> The above 'status_check_url' only shows the status of the first video (Clean video). Notifications for the subsequent two videos will be sent via email.
            </p>
          </>
        );
        } else {
        const message = data.message;
         const match = message.match(/'code': '([^']+)'/);  // Match the error code inside the string

         const errorCode = match ? match[1] : data.message;
        setResponse({ error: errorCode });
      }
    } catch (error) {
      const errorMessage = error?.message || 'Something went wrong. Please try again later.';
      setResponse({ error: errorMessage });
    } finally {
      setLoading(false);
    }
  };

  const renderInput = (label, value, isArray = false) => (
    <div className="flex flex-col sm:flex-row gap-6 w-full border-b border-gray-700 p-2">
      <div className="text-lg font-medium text-gray-300">{label}:</div>
      <div className="text-indigo-400 text-lg">
        {isArray ? (
          value.length ? (
            value.map((url, index) => (
              <div key={index} className="mb-2">
                <a href={url} target="_blank" rel="noopener noreferrer" className="text-indigo-400 hover:text-blue-100">
                  View Image {index + 1}
                </a>
              </div>
            ))
          ) : (
            'No Data Provided'
          )
        ) : (
          value || 'No Data Provided'
        )}
      </div>
    </div>
  );
  

  return (
    <div className="max-w-4xl mx-auto p-4 bg-transparent shadow-2xl rounded-2xl space-y-8">
      {/* Back Button */}
    

      <h1 className="text-3xl font-semibold text-center text-gray-200">Execute Video Generation</h1>
      <h6 className="text-xl text-center text-gray-300">Please review the details of your creation carefully. This information will be used to generate the video. If any changes are needed, click the ‘Back’ button below and make the necessary edits.
      Note: 1 credit will be charged for the video generation process.</h6>


      {alertMessage && <AlertBox message={alertMessage} onClose={() => setAlertMessage('')} />}

      {response && (
        <div className="mt-6 p-6 bg-gray-800 border border-gray-700 rounded-lg shadow-xl">
          {response.error ? (
            <p className="text-red-500">{response.error}</p>
          ) : (
            <div>
              <h3 className="text-xl font-semibold text-gray-300">Explanatory Message:</h3>
              <p className="bg-black text-white p-4 rounded-md border border-gray-600">
                {response.explanatory_message}
              </p>
            </div>
          )}
        </div>
      )}

     

      <div className="mt-2">
        <h3 className="mb-6 text-2xl font-semibold text-gray-200">Your Inputs:</h3>
        {renderInput('Selected Key', selectedKey)}
        {renderInput('Video Name', video_name)}
        {renderInput('Product Name', product_name)}
        {renderInput('Video Dimensions', video_dimensions)}
        {renderInput('Sector', sector)}
        {renderInput('Consumer Message', consumer_message)}
        {renderInput('Duration', duration)}
        {renderInput('Image URLs', image_urls, true)}
        
        {renderInput('Goal', goal)}
        {renderInput('Key Instructions', key_instructions)}
        {renderInput('Layover Text', layover_text)}
        {/* {renderInput('Tags', tags, true)} */}
       {/* {renderInput('Project ID', project_id)}  {/* Added Project ID display */}
       {/* {renderInput('Creation ID', creation_id)}  */} 
      </div>
      <div className="flex justify-between items-center w-full">
      <button
    onClick={() => navigate(-1)}
    className=" px-3 py-2 bg-gradient-to-r from-blue-600 to-indigo-700 text-white rounded-lg hover:bg-blue-500 transition"
  >
    ← Back
  </button>
  <button
    ref={buttonRef}
    onClick={handleVideoGeneration}
    disabled={loading}
    className={`w-1/4 py-2 text-white  rounded-lg ${
      loading ? 'bg-gray-600 cursor-not-allowed' : 'bg-gradient-to-r from-blue-600 to-indigo-700 hover:bg-blue-500'
    } transition duration-300`}
  >
    {loading ? (
      <div className="flex justify-center items-center">
        <svg
          className="w-6 h-6 animate-spin mr-3"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
          <path
            className="opacity-75"
            fill="currentColor"
            d="M4 12a8 8 0 118 8A8 8 0 014 12zm8 0a4 4 0 10-4 4 4 4 0 004-4z"
          ></path>
        </svg>
        Processing...
      </div>
    ) : (
      'Generate Video'
    )}
  </button>


</div>

    </div>
    
  );
};

export default ExecuteVideo;
