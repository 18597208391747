import React, { useState, useEffect} from 'react';
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { FiInfo } from 'react-icons/fi';
import { IoClose } from 'react-icons/io5'; // For the close button





function AlertBox({ message, onClose }) {
  return (
    <div className="fixed inset-0 flex items-center justify-center z-50 bg-gray-900 bg-opacity-75">
      <div className="relative bg-gray-800 border border-indigo-500 rounded-lg p-5 text-center shadow-lg w-[80%] sm:w-[50%]">
        {/* Close Icon */}
        <button
          onClick={onClose}
          className="absolute top-0 right-1 text-gray-400 hover:text-white p-2"
          aria-label="Close"
        >
          ✕
        </button>

        {/* Message */}
        <div className="text-white text-lg">{message}</div>
      </div>
    </div>
  );
}
function AlertBox2({ message, onClose }) {
  return (
    <div className="fixed inset-0 flex items-center justify-center z-50 bg-gray-900 bg-opacity-75">
      <div className="relative bg-gray-800 border border-indigo-500 rounded-lg p-5 text-center shadow-lg w-[80%] sm:w-[50%]">
        {/* Close Icon */}
        <button
          onClick={onClose}
          className="absolute top-0 right-1 text-gray-400 hover:text-white p-2"
          aria-label="Close"
        >
          ✕
        </button>

        {/* Message */}
        <div className="text-white text-lg">{message}</div>
      </div>
    </div>
  );
}
const NewProject2 = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [alertMessage, setAlertMessage] = useState('');
  const [alertMessage2, setAlertMessage2] = useState('');
  const [showCreationInfo, setShowCreationInfo] = useState(false);
  const toggleCreationInfo = () => setShowCreationInfo((prev) => !prev);
  const [showCreationInfo1, setShowCreationInfo1] = useState(false);
  const toggleCreationInfo1 = () => setShowCreationInfo1((prev) => !prev);
  const [showCreationInfo2, setShowCreationInfo2] = useState(false);
  const toggleCreationInfo2 = () => setShowCreationInfo2((prev) => !prev);
  const [showCreationInfo3, setShowCreationInfo3] = useState(false);
  const toggleCreationInfo3 = () => setShowCreationInfo3((prev) => !prev);
  const [showCreationInfo4, setShowCreationInfo4] = useState(false);
  const toggleCreationInfo4 = () => setShowCreationInfo4((prev) => !prev);
  const [showCreationInfo5, setShowCreationInfo5] = useState(false);
  const toggleCreationInfo5 = () => setShowCreationInfo5((prev) => !prev);
const [previewImage, setPreviewImage] = useState(null); // To store preview URL
const [imageFile, setImageFile] = useState(null);


  const [selectedKey, setSelectedKey] = useState(null);
  const [keys, setKeys] = useState([]);
  const [activeInput, setActiveInput] = useState(null); // Track the active input ("file" or "url")


  const { user_id } = location.state || { user_id: 'HYG-90A763-77' }; // Replace with your dynamic user_id source


  const {
    creation_id,
    video_name,
    image_urls,
    prompt_text,
    product_name,
    video_dimensions,
    sector,
    key_instructions,
    Goal,
    layover_text,
    consumer_message,
    Tags,
    context,
    duration,
    project_id,// Added duration
  } = location.state || {};

  const [creationId, setCreationId] = useState(creation_id || '');
  const [videoName, setVideoName] = useState(video_name || '');
  const [imageUrls, setImageUrls] = useState(image_urls || '');
  const [productName, setProductName] = useState(product_name || '');
  const [dimensions, setDimensions] = useState(video_dimensions || '');
  const [Sector, setSector] = useState(sector || '');
  const [keyInstructions, setKeyInstructions] = useState(key_instructions || '');
  const [goal, setGoal] = useState(Goal || '');
  const [layoverText, setLayoverText] = useState(layover_text || '');
  const [consumerMessage, setConsumerMessage] = useState(consumer_message || '');
  const [tags, setTags] = useState(Tags || '');
  const [Context, setContext] = useState(context || '');
  const [promptText, setPromptText] = useState(prompt_text || '');
  const [videoDuration, setVideoDuration] = useState(duration || ''); // Added state for duration
  const [hasSubmitted, setHasSubmitted] = useState(true); // Add state to track if form is submitted
  const [, setStatus] = useState('');
  const [isPhysicalProductSelected, setIsPhysicalProductSelected] = useState(false);
  const dimensionOptions = ['9:16', '2:3', '16:9'];
  const durationOptions = ['5', '10'];
  const sectorOptions = [
    'Technology', 'Ecommerce', 'Wellness', 'Beauty', 'Fashion',
    'Healthcare', 'Finance', 'Education', 'Retail'
  ];
  const goalOptions = [
    'Brand Awareness Video',
    'Re-targetting / Abandoned Cart',
    'Others'
  ];


  const [isAlertBoxVisible, setIsAlertBoxVisible] = useState(false);
  const [updatedPayload, setUpdatedPayload] = useState(null);
const [isLoading, setIsLoading] = useState(false);  // Tracks loading state
const [, setLoadingMessage] = useState('');  // Stores custom loading message


  const handleExecuteForVideo = async (e) => {
    if (e) e.preventDefault();
  
    // Start loading animation
    setIsLoading(true);
    setLoadingMessage('Submitting your creation... Please wait.');
  
    if (!validateForm()) {
      console.log('Form validation failed. Submission aborted.');
      setIsLoading(false);  // Stop loading if validation fails
      return;
    }
  
    try {
      const submitResult = await handleSubmit(e);  // Handle submission logic

      console.log("submitResult from handleSubmit:", submitResult); // Debugging line
      if (submitResult.status === 'success') {
        setAlertMessage('')

      }
      // If submitResult indicates an error, stop further execution
      if (submitResult.status === 'error') {
        setAlertMessage(submitResult.error.message || 'Failed to submit creation.');
        return;
      }


  

  
      const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || '';
  
      const response = await fetch(`${API_BASE_URL}/list_keys?user_id=${user_id}`);
      const data = await response.json();
  
      if (data.status === 'success') {
        setKeys(data.data.keys || []);
        setIsAlertBoxVisible(true);  // Show alert box after success
      } else {
        setAlertMessage(data.message || 'Failed to fetch keys.');
      }
    } catch (error) {
      console.log('Error:', error);
      setAlertMessage('An error occurred while fetching keys.',error.message);
    } finally {
      // End loading animation after everything is completed
      setIsLoading(false);
      setLoadingMessage('');  // Clear loading message
    }
  };
  
  // Handle product type selection
  const handleProductTypeChange = (isPhysical) => {
    setIsPhysicalProductSelected(isPhysical);
    console.log(`Product Type Selected: ${isPhysical ? 'Physical' : 'Digital'}`);
  };

  const handleKeySelection = (event) => {
    setSelectedKey(event.target.value);
  };
  
  useEffect(() => {
    if (updatedPayload && !hasSubmitted) {  // Only run the effect if payload is updated and form hasn't been submitted yet
      console.log('Updated payload:', updatedPayload);
      console.log('Navigating to /executevideo with updatedPayload:', updatedPayload);
      console.log(selectedKey);

      navigate('/executevideo', { state: { updatedCreation: updatedPayload, selectedKey: selectedKey } });

    }
  }, [updatedPayload, navigate, selectedKey, hasSubmitted]);
  useEffect(() => {
    if (updatedPayload) {
      setCreationId(updatedPayload.creation_id || '');
      setVideoName(updatedPayload.video_name || '');
      setImageUrls(updatedPayload.image_urls ? updatedPayload.image_urls.join(', ') : '');
      setProductName(updatedPayload.product_name || '');
      setDimensions(updatedPayload.video_dimensions || '');
      setSector(updatedPayload.sector || '');
      setKeyInstructions(updatedPayload.key_instructions || '');
      setGoal(updatedPayload.goal || '');
      setLayoverText(updatedPayload.layover_text || '');
      setPromptText(updatedPayload.prompt_text || '');
      setConsumerMessage(updatedPayload.consumer_message || '');
      setTags(updatedPayload.tags ? updatedPayload.tags.join(', ') : '');
      setContext(updatedPayload.context || '');
      setVideoDuration(updatedPayload.duration || '');
      setIsPhysicalProductSelected(updatedPayload.is_physical_product || false);
    }
  }, [updatedPayload]);
  useEffect(() => {
    if (creationId) {
      const storedPayload = localStorage.getItem(`updatedPayload_${creationId}`);
      if (storedPayload) {
        setUpdatedPayload(JSON.parse(storedPayload));
      }
    }
  }, [creationId]);

  useEffect(() => {
    if (creationId && updatedPayload) {
      localStorage.setItem(`updatedPayload_${creationId}`, JSON.stringify(updatedPayload));
    }
  }, [creationId, updatedPayload]);

  useEffect(() => {
    if (creation_id) setCreationId(creation_id);
  }, [creation_id]);

  useEffect(() => {
    if (video_name) setVideoName(video_name);
  }, [video_name]);

  useEffect(() => {
    if (image_urls) {
      setImageUrls(
        Array.isArray(image_urls) ? image_urls : image_urls.split(', ').map(url => url.trim())
      );
    }
  }, [image_urls]);
  
  useEffect(() => {
    if (product_name) setProductName(product_name);
  }, [product_name]);

  useEffect(() => {
    if (video_dimensions) {
      console.log('Updating dimensions:', video_dimensions);
      setDimensions(video_dimensions);
    }
  }, [video_dimensions]);

  useEffect(() => {
    if (sector) {
      console.log('Updating sector:', sector);
      setSector(sector);
    }
  }, [sector]);

  useEffect(() => {
    if (key_instructions) setKeyInstructions(key_instructions);
  }, [key_instructions]);

  useEffect(() => {
    if (Goal) setGoal(Goal);
  }, [Goal]);

  useEffect(() => {
    if (layover_text) setLayoverText(layover_text);
  }, [layover_text]);

  useEffect(() => {
    if (prompt_text) setPromptText(prompt_text);
  }, [prompt_text]);
  

  useEffect(() => {
    if (consumer_message) setConsumerMessage(consumer_message);
  }, [consumer_message]);

  useEffect(() => {
    if (Tags) setTags(Tags.join(', '));  // Join tags into a comma-separated string
  }, [Tags]);

  useEffect(() => {
    if (context) setContext(context);
  }, [context]);

  useEffect(() => {
    if (duration) setVideoDuration(duration);
  }, [duration]); // Added useEffect for duration

  const validateForm = () => {
   // Check if there is an image URL or an uploaded image
if (!imageUrls || (Array.isArray(imageUrls) && imageUrls.length === 0)) {
  // If imageUrls is empty, check for uploaded images
  if (!imageFile || imageFile.length === 0) {
    setAlertMessage('Please upload at least one image or provide an image URL.');
    return false;
  }
} 

    
    if (!Sector.trim()) {
      setAlertMessage('Sector field must be selected.');
      return false;
    }

    if (!dimensions.trim()) {
      setAlertMessage('Please select Video Dimensions.');
      return false;
    }

    if (!videoDuration.trim()) {
      setAlertMessage('Please select Video Duration.');
      return false;
    }
    if (!keyInstructions.trim()) {
      setAlertMessage('Key Instructions field cannot be empty.');
      return false;
    }

    if (!goal.trim()) {
      setAlertMessage('Please select a Goal.');
      return false;
    }

    return true;
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
  
    // Prepare image URLs payload
    let imageUrlsPayload = [];
    if (typeof imageUrls === 'string' && imageUrls.trim()) {
      imageUrlsPayload = imageUrls.split(',').map((url) => url.trim());
    }
  
    console.log('Image file before uploading:', imageFile);
  
    const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || '';
  
    try {
      // Upload the image file if provided
      if (imageFile) {
        const formData = new FormData();
        formData.append('image_urls', imageFile);
        formData.append('creation_id', creationId);
        formData.append('video_name', videoName);
        formData.append('product_name', productName);
        formData.append('video_dimensions', dimensions);
        formData.append('sector', Sector);
        formData.append('key_instructions', keyInstructions);
        formData.append('goal', goal);
        formData.append('layover_text', layoverText);
        formData.append('prompt_text', promptText);
        formData.append('consumer_message', consumerMessage);
        formData.append(
          'tags',
          JSON.stringify(
            typeof tags === 'string' ? tags.split(',').map((tag) => tag.trim()) : []
          )
        );
        formData.append('context', Context);
        formData.append('is_physical_product', isPhysicalProductSelected);
        formData.append('duration', videoDuration);
        formData.append('project_id', project_id);
  
        const uploadResponse = await fetch(`${API_BASE_URL}/update_creation/`, {
          method: 'POST',
          body: formData,
        });
  
        const uploadData = await uploadResponse.json();
        console.log('Upload response:', uploadData);
  
        if (uploadData.status === 'success') {
          if (uploadData.data?.updated_fields?.image_urls?.length > 0) {
            imageUrlsPayload.push(...uploadData.data.updated_fields.image_urls);
          }
        } else {
          throw new Error(uploadData.message || 'Image upload failed.');
        }
      }
  
      // Prepare payload for update creation API
      const payload = {
        creation_id: creationId,
        video_name: videoName,
        image_urls: imageUrlsPayload,
        product_name: productName,
        video_dimensions: dimensions,
        sector: Sector,
        key_instructions: keyInstructions,
        goal: goal,
        layover_text: layoverText,
        prompt_text: promptText,
        consumer_message: consumerMessage,
        tags: typeof tags === 'string' ? tags.split(',').map((tag) => tag.trim()) : [],
        context: Context,
        is_physical_product: isPhysicalProductSelected,
        duration: videoDuration,
        project_id: project_id,
      };
  
      console.log('Payload before sending to update_creation API:', payload);
  
      const response = await fetch(`${API_BASE_URL}/update_creation/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
      });
  
      const data = await response.json();
      console.log('Response from /update_creation:', data);
  
      if (data.status === 'success') {
        setAlertMessage('Creation updated successfully!');
        setStatus('success');
        setUpdatedPayload(payload);  // Assuming 'payload' is defined somewhere
        setHasSubmitted(true);  // Assuming 'hasSubmitted' is defined
        return { status: 'success' };  // Return success when the operation is successful
      } else {
        // If response indicates failure, throw an error
        throw new Error(data.message || 'Failed to update creation.');
      }
    } catch (error) {
      console.error('Error occurred:', error.message || error);
  
      // Handle specific errors
      if (error instanceof TypeError) {
        setAlertMessage('Network error: Unable to reach the server. Please check your connection.');
      } else {
        setAlertMessage(error.message || 'An unexpected error occurred. Please try again later.');
      }
  
      // Return an error object with status and message
      setStatus('error');
      return { status: 'error', error: { message: error.message || 'An unexpected error occurred.' } }; // Return error object
    }
  };
  
const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setImageFile(file);
      setActiveInput('file');
      setPreviewImage(URL.createObjectURL(file)); // Set the preview image
    }
  };
  ;

  const handleUrlChange = (e) => {
    setImageUrls(e.target.value);
    setActiveInput('url');
    setImageFile(null); // Clear file when switching to URL input
  };

  const clearImageUrls = () => {
    setImageUrls('');
    setActiveInput(''); // Reset the active input
  };


  return (
<div
  className={`flex items-center justify-center min-h-screen bg-transparent m-0 relative z-10`}
>      <form onSubmit={handleSubmit} className="bg-gray-800 rounded-lg shadow-lg p-6 w-full max-w-xl">
        <button
          onClick={() => navigate(-1)}
          className="mb-3 px-3 py-2 bg-gradient-to-r from-blue-600 to-indigo-700 text-white rounded-lg hover:bg-blue-500 transition"
        >
          ← Back
        </button>
        <h2 className="text-2xl font-bold text-center text-gray-300 mb-4">Update Creation Details</h2>

        {alertMessage && (
          <AlertBox message={alertMessage} onClose={() => setAlertMessage('')} />
        )}
        {isAlertBoxVisible && (
          <AlertBox
            message={
              <div className="relative">
                <div className="flex items-center gap-2 mb-1 ">

                  <label className="block text-gray-200 mb-2 text-xl">Select a Key</label>
                  <button
                    type="button"
                    onClick={toggleCreationInfo5}
                    className="text-white hover:bg-indigo-700 transition duration-300 focus:outline-none rounded-full w-4 h-4 mb-1 flex items-center justify-center"
                  >
                    <FiInfo size={16} />
                  </button>
                </div>

                {showCreationInfo5 && (
                  <div className="absolute bg-gray-700 text-white text-sm rounded-lg p-3 shadow-md w-80 mt-2 z-50">
                    <p>
                      A unique key is required to process your request. If you haven’t generated one yet, please go to the ‘Generate Key’ section. Once created, it will appear in the dropdown menu for selection.                                         </p>
                  </div>
                )}
                <select
                  className="w-full p-2 bg-gray-700 text-white rounded"
                  onChange={handleKeySelection}
                  defaultValue=""
                >
                  <option value="" disabled>
                    Choose a key
                  </option>
                  {keys.map((key) => (
                    <option key={key.key_id} value={key.key_id}>
                      {key.key_name}
                    </option>
                  ))}
                </select>

                {/* Action Buttons */}
                <div className="mt-4 flex justify-between space-x-4">
                  <button
                    className="w-full bg-blue-500 text-white p-2 rounded hover:bg-blue-600 transition duration-300"
                    onClick={() => navigate('/generatekey')}
                  >
                    Generate New Key
                  </button>
                  <button
                    className="w-full bg-green-500 text-white p-2 rounded hover:bg-green-600 transition duration-300"
                    onClick={async () => {
                      if (selectedKey) {
                        console.log(selectedKey);
                        try {
                          const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || '';
                          const response = await fetch(`${API_BASE_URL}/verify_api_key_id/`, {
                            method: 'POST',
                            headers: {
                              'Content-Type': 'application/json',
                            },
                            body: JSON.stringify({
                              key_id: selectedKey, // Use the selected key's ID
                            }),
                          });

                          const data = await response.json();

                          if (response.ok && data.status === 'success') {
                            setAlertMessage2('Key Verified! Redirecting to Video Generation page...');
                            setTimeout(() => {
                              setHasSubmitted(false);
                            }, 3000);
                          } else {
                            alert(`Verification Failed: ${data.message}`);
                          }
                        } catch (error) {
                          console.error('Error verifying key:', error);
                          alert('An error occurred while verifying the key.');
                        }
                      } else {
                        alert('Please select a key before verifying.');
                      }
                    }}
                  >
                    Verify Key
                  </button>
                </div>
              </div>
            }
            onClose={() => setIsAlertBoxVisible(false)}
          />
        )}


        {alertMessage2 && (
          <AlertBox2 message={alertMessage2} onClose={() => setAlertMessage2('')} />
        )}




        {/* Creation ID Input */}
        <div className="mb-4">
          <div className="flex items-center gap-2 mb-1">

            <label className="block text-gray-100 mb-1" htmlFor="creationId">Creation ID</label>
            <button
              type="button"
              onClick={toggleCreationInfo}
              className="text-white hover:bg-indigo-700 transition duration-300 focus:outline-none rounded-full w-4 h-4 mb-1 flex items-center justify-center"
            >
              <FiInfo size={16} />
            </button>
          </div>

          {showCreationInfo && (
            <div className="absolute bg-gray-700 text-white text-sm rounded-lg p-3 shadow-md w-80 mt-2 z-50">
              <p>
                A unique identifier assigned to each creation. You can use the creation ID to view all videos associated with that specific creation.
              </p>
            </div>
          )}

          <input
            type="text"
            id="creationId"
            value={creationId}
            onChange={(e) => setCreationId(e.target.value)}
            required
            className="w-full p-2 rounded bg-gray-700 text-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-400"
          />
        </div>

        {/* Video Name Input */}
        <div className="mb-4">
          <div className="flex items-center gap-2 mb-1">

            <label className="block text-gray-100 mb-1" htmlFor="videoName">Video Name</label>
            <button
              type="button"
              onClick={toggleCreationInfo1}
              className="text-white hover:bg-indigo-700 transition duration-300 focus:outline-none rounded-full w-4 h-4 mb-1 flex items-center justify-center"
            >
              <FiInfo size={16} />
            </button>
          </div>

          {showCreationInfo1 && (
            <div className="absolute bg-gray-700 text-white text-sm rounded-lg p-3 shadow-md w-80 mt-2 z-50">
              <p>
                Provide a unique name for your video.
              </p>
            </div>
          )}
          <input
            type="text"
            id="videoName"
            value={videoName}
            onChange={(e) => setVideoName(e.target.value)}
            required
            className="w-full p-2 rounded bg-gray-700 text-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-400"
          />
        </div>
       

        <div className="mb-4">
  {/* Unified Drag-and-Drop and File Upload */}
  <label className="block text-gray-100 mb-2" htmlFor="imageFile">
    Upload Image
  </label>
  <div
    onDragOver={(e) => e.preventDefault()}
    onDrop={(e) => {
      e.preventDefault();
      if (!imageUrls) {
        const file = e.dataTransfer.files[0];
        if (file) {
          handleFileChange({ target: { files: [file] } });
          setActiveInput('file');
        }
      }
    }}
    className={`w-full p-6 text-center rounded border-2 border-dashed ${
      imageUrls
        ? 'border-gray-700 bg-gray-800 text-gray-500 cursor-not-allowed'
        : activeInput === 'url'
        ? 'border-gray-700 bg-gray-800 text-gray-400'
        : 'border-indigo-400 bg-gray-700 text-gray-300'
    } hover:bg-gray-600 transition duration-200 relative`}
  >
    <p className={`text-sm mb-2 ${imageUrls ? 'text-gray-500' : ''}`}>
      {imageUrls
        ? 'Clear the URL to upload an image'
        : 'Drag and drop an image file here, or '}
      {!imageUrls && (
        <label
          htmlFor="imageFile"
          className="text-indigo-400 font-medium cursor-pointer underline"
        >
          browse
        </label>
      )}
    </p>

    <input
      type="file"
      id="imageFile"
      onChange={(e) => {
        handleFileChange(e);
        setActiveInput('file');
      }}
      className="hidden"
      disabled={!!imageUrls}
    />

    {/* Preview for Uploaded Image */}
    {previewImage && (
      <div className="mt-4 relative">
        <img
          src={previewImage}
          alt="Uploaded Preview"
          className="w-48 h-48 object-contain  mx-auto"
          style={{ maxWidth: '100%', maxHeight: '12rem' }}
        />
        <button
          type="button"
          onClick={() => {
            setPreviewImage(null);
            setImageFile(null);
            setActiveInput(null);
          }}
          className="absolute top-2 right-2 bg-red-500 text-white rounded-full p-1 hover:bg-red-600 transition duration-200"
          aria-label="Remove Image"
        >
          ✕
        </button>
      </div>
    )}
  </div>
</div>

{/* Image URLs */}
<div className="grid grid-cols-1 gap-5 sm:grid-cols-[55%_41%]">
  <div className="mb-4">
    <div className="flex items-center gap-2">
      <label className="block text-gray-100 mb-1" htmlFor="imageUrls">
        Image URLs (comma-separated) <span className="text-red-500">*</span>
      </label>
      <button
        type="button"
        onClick={toggleCreationInfo2}
        className="text-white hover:bg-indigo-700 transition duration-300 focus:outline-none rounded-full w-4 h-4 mb-1 flex items-center justify-center"
      >
        <FiInfo size={16} />
      </button>
    </div>

    {showCreationInfo2 && (
      <div className="absolute bg-gray-700 text-white text-sm rounded-lg p-3 shadow-md w-80 mt-2 z-50">
        <p>Add an image URL. Ensure the URL is public and directly accessible via a browser.</p>
      </div>
    )}

    {/* URL Input with Clear Button */}
    <div className="relative">
      <input
        type="text"
        id="imageUrls"
        value={imageUrls}
        onChange={handleUrlChange}
        // onFocus={() => setActiveInput('url')} // Switch to URL input
        className="w-full p-2 pr-10 rounded bg-gray-700 text-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-400"
        disabled={activeInput === 'file'} // Disable if file input is active
      />
      {imageUrls && (
        <button
          type="button"
          onClick={clearImageUrls}
          className="absolute top-1/2 transform -translate-y-1/2 right-1 text-gray-400 hover:text-white bg-gray-800 hover:bg-indigo-600 rounded-full p-1 transition duration-200 focus:outline-none"
          aria-label="Clear Image URLs"
        >
          <IoClose size={18} />
        </button>
      )}
    </div>
  </div>


          <div className="mb-4 ">
            <label className="block text-gray-100 mb-1 " htmlFor="productName">Product Name</label>
            <input
              type="text"
              id="productName"
              value={productName}
              onChange={(e) => setProductName(e.target.value)}
              className="w-full p-2 rounded bg-gray-700 text-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-400"
            />
          </div>
        </div>

        {/* Dimensions Input */}

        <div className="mb-4">
          <label className="block text-gray-100 mb-2">
            Is this a Physical Product (e.g., Ecom/Fashion or Real Estate)?
          </label>
          <div className="flex items-center gap-4">
            <label className="inline-flex items-center text-gray-400">
              <input
                type="radio"
                name="productType"
                checked={isPhysicalProductSelected === true}
                onChange={() => handleProductTypeChange(true)}
                className="mr-2"
              />
              Yes
            </label>
            <label className="inline-flex items-center text-gray-400">
              <input
                type="radio"
                name="productType"
                checked={isPhysicalProductSelected === false}
                onChange={() => handleProductTypeChange(false)}
                className="mr-2"
              />
              No
            </label>
          </div>
        </div>
        {/* Sector Input */}
        <div className="mb-4">
          <label className="block text-gray-100 mb-1" htmlFor="Sector">Sector <span className="text-red-500">*</span></label>

          <select
            id="Sector"
            value={Sector}
            onChange={(e) => setSector(e.target.value)}
            className="w-full p-2 rounded bg-gray-700 text-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-400"

          >
            <option value="">Select a sector</option>
            {sectorOptions.map((sectorOption) => (
              <option key={sectorOption} value={sectorOption}>
                {sectorOption}
              </option>
            ))}
          </select>

        </div>

        <div className="grid grid-cols-1 gap-4 sm:grid-cols-2">
          <div className="mb-4">
            <label className="block text-gray-100 mb-1" htmlFor="dimensions">Video Dimensions <span className="text-red-500">*</span></label>
            <select
              id="dimensions"
              value={dimensions}

              onChange={(e) => {
                console.log('Selected dimension:', e.target.value);
                setDimensions(e.target.value);
              }} className="w-full p-2 rounded bg-gray-700 text-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-400"
            >
              <option value="">Select Video Dimensions</option>
              {dimensionOptions.map((option) => (
                <option key={option} value={option}>{option}</option>
              ))}
            </select>
          </div>

          <div className="mb-4">
            <label className="block text-gray-100 mb-1" htmlFor="videoDuration">Video Duration <span className="text-red-500">*</span></label>
            <select
              id="videoDuration"
              value={videoDuration}
              onChange={(e) => setVideoDuration(e.target.value)}
              className="w-full p-2 rounded bg-gray-700 text-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-400"
            >
              <option value="">Select Video Duration</option>
              {durationOptions.map((option) => (
                <option key={option} value={option}>{option}</option>
              ))}
            </select>
          </div>
        </div>

        <div className="mb-4">
          <label className="block text-gray-100 mb-1" htmlFor="layoverText">Overlay Text</label>
          <input
            type="text"
            id="layoverText"
            value={layoverText}
            onChange={(e) => setLayoverText(e.target.value)}
            className="w-full p-2 rounded bg-gray-700 text-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-400"
          />
        </div>

        <div className="mb-4">
          <label className="block text-gray-100 mb-1" htmlFor="consumerMessage">Customer Message</label>
          <textarea
            id="consumerMessage"
            value={consumerMessage}
            onChange={(e) => setConsumerMessage(e.target.value)}
            className="w-full p-2 rounded bg-gray-700 text-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-400"
          ></textarea>
        </div>

        <div className="mb-4">

          <label className="block text-gray-100 mb-1" htmlFor="tags">Tags (comma-separated)</label>
          <input
            type="text"
            id="tags"
            value={tags}
            onChange={(e) => setTags(e.target.value)}
            className="w-full p-2 rounded bg-gray-700 text-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-400"
          />
        </div>
        <div className="mb-4">
          <div className="flex items-center gap-2">

            <label className="block text-gray-100 mb-1" htmlFor="keyInstructions">Key Instructions<span className="text-red-500">*</span></label>
            <button
              type="button"
              onClick={toggleCreationInfo3}
              className="text-white hover:bg-indigo-700 transition duration-300 focus:outline-none rounded-full w-4 h-4 mb-1 flex items-center justify-center"
            >
              <FiInfo size={16} />
            </button>
          </div>

          {showCreationInfo3 && (
            <div className="absolute bg-gray-700 text-white text-sm rounded-lg p-3 shadow-md w-80 mt-2 z-50">
              <p>
                Specify the type of movement, animation, or effects you want to see when your image is transformed into a video. While the model may not replicate the instructions perfectly, it will prioritize them during video creation.
              </p>            </div>
          )}
          <textarea
            id="keyInstructions"
            value={keyInstructions}
            onChange={(e) => setKeyInstructions(e.target.value)}
            className="w-full p-2 rounded bg-gray-700 text-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-400"
          ></textarea>
        </div>

        <div className="grid grid-cols-1 gap-4 sm:grid-cols-2">
          <div className="mb-4">
            <label className="block text-gray-100 mb-1" htmlFor="goal">Goal <span className="text-red-500">*</span></label>
            <select
              id="goal"
              value={goal}
              onChange={(e) => setGoal(e.target.value)}
              className="w-full p-2 rounded bg-gray-700 text-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-400"
            >
              <option value="">Select a Goal</option>

              {goalOptions.map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </select>
          </div>


          <div className="mb-4">
            <div className="flex items-center gap-2">

              <label className="block text-gray-100 mb-1" htmlFor="Context">Context</label>
              <button
                type="button"
                onClick={toggleCreationInfo4}
                className="text-white hover:bg-indigo-700 transition duration-300 focus:outline-none rounded-full w-4 h-4 mb-1 flex items-center justify-center"
              >
                <FiInfo size={16} />
              </button>
            </div>

            {showCreationInfo4 && (
              <div className="absolute bg-gray-700 text-white text-sm rounded-lg p-3 shadow-md w-80 mt-2 z-50">
                <p>
                  Provide any relevant background context or additional information that might guide the video creation process.

                </p>
              </div>
            )}
            <input
              type="text"
              id="Context"
              value={Context}
              onChange={(e) => setContext(e.target.value)}
              className="w-full p-1.5 rounded bg-gray-700 text-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-400"
            />
          </div>
        </div>
        <div className="mb-4">
          <label className="block text-gray-100 mb-1" htmlFor="promptText">Prompt Text</label>
          <input
            type="text"
            id="promptText"
            value={promptText}
            onChange={(e) => setPromptText(e.target.value)}
            className="w-full p-2 rounded bg-gray-700 text-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-400"
          />
        </div>

        <div className="flex space-x-4">
          <button
            type="submit"
            onClick={handleSubmit}
            className="w-full p-2 bg-indigo-500 text-white rounded hover:bg-indigo-600 transition duration-300"
          >
            Update Creation
          </button>
          <button
                      type="button"
 
  onClick={handleExecuteForVideo}
  disabled={isLoading }
  className="w-full p-2 bg-green-500 text-white rounded hover:bg-green-600 transition duration-300 disabled:bg-gray-600 disabled:cursor-not-allowed"
>
  {isLoading  ? (
    <div className="flex justify-center items-center">
      <svg
        className="w-6 h-6 animate-spin mr-3"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
        <path
          className="opacity-75"
          fill="currentColor"
          d="M4 12a8 8 0 118 8A8 8 0 014 12zm8 0a4 4 0 10-4 4 4 4 0 004-4z"
        ></path>
      </svg>
      Processing...
    </div>
  ) : (
    'Execute for Video'
  )}
</button>

        </div>

      </form>
    </div>
  );
};

export default NewProject2;
