import React, { useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { FaUserAlt, FaKey, FaEnvelope, FaEye, FaEyeSlash } from 'react-icons/fa';

function AlertBox({ message, onClose, redirectToLogin, navigate }) {
  return (
    <div className="fixed inset-0 flex items-center justify-center z-50 bg-gray-900 bg-opacity-75">
      <div className="bg-gray-800 border border-indigo-500 rounded-lg p-5 text-center shadow-lg w-[80%] sm:w-[50%]">
        <p className="text-white text-lg mb-4">{message}</p>
        {redirectToLogin && (
          <>
            <p className="text-gray-400 text-sm mb-4">
              Redirecting to the login page in 10 seconds...
            </p>
            <button
              onClick={() => navigate('/login')}
              className="mt-2 px-4 py-2 bg-indigo-600 text-white rounded-lg hover:bg-indigo-700 focus:outline-none"
            >
              Go to Login
            </button>
          </>
        )}
        <button
          onClick={onClose}
          className="mt-4 px-4 py-2 bg-gray-700 text-white rounded-lg hover:bg-gray-600 focus:outline-none"
        >
          Done
        </button>
      </div>
    </div>
  );
}

export default function SignUp({ setUser }) {
  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [redirectToLogin, setRedirectToLogin] = useState(false);
  const navigate = useNavigate();

  const handleSignUp = async () => {
    if (username.trim() && email.trim() && password.trim() && confirmPassword.trim()) {
      if (password !== confirmPassword) {
        setAlertMessage('Passwords do not match');
        return;
      }

      const userData = { username, email, password };

      try {
        const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || '';

        const response = await fetch(`${API_BASE_URL}/signup/`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(userData),
        });

        const text = await response.text();
        const data = JSON.parse(text);

        if (response.ok && data.status === 'success') {
          setAlertMessage(
            <>
              <p>Great! Your Signup is successful!</p>
              <p>Now you can login into your account.</p>
              <p>
                A verification email has been sent to your email. Please click on the
                verification button to verify your account within the next 72 hours.
              </p>
            </>
          );
          setRedirectToLogin(true);
          setTimeout(() => navigate('/login'), 10000); // Redirect after 10 seconds
        } else {
          setAlertMessage('Signup failed: ' + data.message);
        }
      } catch (error) {
        console.error('Error during signup:', error.message);
        setAlertMessage('There was an error with the signup process.');
      }
    } else {
      setAlertMessage('Please fill out all fields');
    }
  };

  return (
    <div className="flex h-[100vh] bg-gradient-to-b from-gray-900 to-gray-800 overflow-hidden items-center justify-center">
      {alertMessage && (
        <AlertBox
          message={alertMessage}
          onClose={() => {
            setAlertMessage('');
            if (redirectToLogin) {
              navigate('/login'); 
            }
          }}
          redirectToLogin={redirectToLogin}
          navigate={navigate}
        />
      )}

      <div className="absolute top-5 text-center">
        <img src="/logo.gif" alt="Logo" className="w-20 h-auto mx-auto" />
      </div>

      <div className="bg-transparent p-3 sm:p-10 rounded-lg shadow-xl w-[90%] sm:w-[45%] relative z-20 text-center items-center justify-center border-2 border-gray-600 top-5">
        <h2 className="text-indigo-400 text-3xl font-semibold mb-1">Sign Up</h2>
        <p className="text-gray-300 text-lg mb-4">
          Join us for a new experience!
        </p>

        <div className="relative mb-3 w-full mx-auto">
          <FaUserAlt className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-500" />
          <input
            type="text"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            placeholder="Enter Username"
            className="pl-10 pr-3 py-2 text-lg rounded w-full border border-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-500"
          />
        </div>

        <div className="relative mb-3 w-full mx-auto">
          <FaEnvelope className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-500" />
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Enter Email"
            className="pl-10 pr-3 py-2 text-lg rounded w-full border border-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-500"
          />
        </div>

        <div className="flex space-x-2 w-full mx-auto">
          <div className="relative mb-3 w-1/2">
            <FaKey className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-500" />
            <input
              type={showPassword ? 'text' : 'password'}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              placeholder="Enter Password"
              className="pl-10 pr-10 py-2 text-lg rounded w-full border border-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-500"
            />
            <div
              className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-500 cursor-pointer"
              onClick={() => setShowPassword(!showPassword)}
            >
              {showPassword ? <FaEyeSlash /> : <FaEye />}
            </div>
          </div>

          <div className="relative mb-3 w-1/2">
            <FaKey className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-500" />
            <input
              type={showConfirmPassword ? 'text' : 'password'}
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              placeholder="Confirm Password"
              className="pl-10 pr-10 py-2 text-lg rounded w-full border border-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-500"
            />
            <div
              className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-500 cursor-pointer"
              onClick={() => setShowConfirmPassword(!showConfirmPassword)}
            >
              {showConfirmPassword ? <FaEyeSlash /> : <FaEye />}
            </div>
          </div>
        </div>

        <button
          onClick={handleSignUp}
          className="px-6 py-2 text-white bg-indigo-600 rounded-lg w-full hover:bg-indigo-700 transition-all duration-300 focus:outline-none focus:ring-2 focus:ring-indigo-500"
        >
          Sign Up
        </button>

        <p className="text-gray-300 text-md mt-4">
          Already have an account?{' '}
          <Link to="/login" className="text-indigo-400 hover:underline">
            Login
          </Link>
        </p>
      </div>
    </div>
  );
}
