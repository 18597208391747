import React, { useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { FaUserAlt, FaKey, FaEye, FaEyeSlash } from 'react-icons/fa';
import { useLocation } from 'react-router-dom';


function AlertBox({ message, onClose }) {
  return (
    <div className="fixed inset-0 flex items-center justify-center z-50 bg-gray-900 bg-opacity-75">
      <div className="bg-gray-800 border border-indigo-500 rounded-lg p-5 text-center shadow-lg w-[80%] sm:w-[50%]">
        <p className="text-white text-lg">{message}</p>
        <button
          onClick={onClose}
          className="mt-4 px-4 py-2 bg-indigo-600 text-white rounded-lg hover:bg-indigo-700 focus:outline-none"
        >
          Close
        </button>
      </div>
    </div>
  );
}
export default function Login({ setUser }) {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [resetEmail, setResetEmail] = useState(''); // For password reset
  const [showResetModal, setShowResetModal] = useState(false); // Toggle modal for reset password
  const [showPassword, setShowPassword] = useState(false); // Toggle password visibility
  const [alertMessage, setAlertMessage] = useState('');
  const navigate = useNavigate();
  const location = useLocation();


  const { isEmailVerified } = location.state || {};


  const handleLogin = async () => {
    if (email.trim() && password.trim()) {
      const loginData = {
        email: email,
        password: password,
      };

      try {
        const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || '';
        const response = await fetch(`${API_BASE_URL}/login/`, {  
                  method: 'POST',

          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(loginData),
        });

        const data = await response.json();

        if (response.ok && data.status === 'success') {
          localStorage.setItem('user_id', data.data.user_id);
          localStorage.setItem('username', data.data.username);
          localStorage.setItem('email', data.data.email);
          localStorage.setItem('roles', JSON.stringify(data.data.roles));

          setUser(data.data.username);
          console.log('Received state from signup to login:', { isEmailVerified });
          console.log('Navigating with state from login to dashboard:', { isEmailVerified }); 
          navigate('/', { state: { user_id: data.data.user_id,email:data.data.email, isEmailVerified } });
        } else {
          setAlertMessage('Login failed: ' + data.message);
        }
      } catch (error) {
        console.error('Error during login:', error.message);
        setAlertMessage(error.message);
      }
    } else {
      setAlertMessage('Please enter both email and password');
    }
  };

  const handlePasswordReset = async () => {
    if (!resetEmail.trim()) {
      setAlertMessage('Please enter an email address');
      return;
    }

    try {
      const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || '';

      const response = await fetch(`${API_BASE_URL}/reset_password_request/`, {        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email: resetEmail }),
      });

      const data = await response.json();

      if (data.status === 'success') {
        setAlertMessage(data.message);
        setShowResetModal(false);
        setResetEmail('');
      } else {
        setAlertMessage('Password reset failed: ' + data.message);
      }
    } catch (error) {
      console.error('Error during password reset:', error.message);
      setAlertMessage('An error occurred during the password reset process.');
    }
  };

  return (
    <div className="flex h-[100vh] bg-gradient-to-b from-gray-900 to-gray-800 overflow-hidden items-center justify-center">
{alertMessage && (
        <AlertBox message={alertMessage} onClose={() => setAlertMessage('')} />
      )}
      <div className="absolute top-5 text-center">
        <img src="/logo.gif" alt="Logo" className="w-20 h-auto mx-auto" />
      </div>

      <div className="bg-transparent p-3 sm:p-10 rounded-lg shadow-xl w-[90%] sm:w-[45%] relative z-20 text-center items-center justify-center border-2 border-gray-600 top-5">
        <h1 className="text-indigo-400 text-3xl font-semibold mb-1">HyGaar</h1>
        <p className="text-gray-300 text-lg mb-4">
        Our Platform. Your Story. 
        <span className="text-md font-bold text-indigo-100"> In every frame.</span>        </p>

        <div className="relative mb-3 w-full">
          <FaUserAlt className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-500" />
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Enter Email"
            className="pl-10 pr-3 py-2 text-lg rounded w-full border border-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-500"
          />
        </div>

        <div className="relative mb-3 w-full">
          <FaKey className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-500" />
          <input
            type={showPassword ? "text" : "password"}
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder="Enter Password"
            className="pl-10 pr-10 py-2 text-lg rounded w-full border border-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-500"
          />
          <div
            className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-500 cursor-pointer"
            onClick={() => setShowPassword(!showPassword)}
          >
            {showPassword ? <FaEyeSlash /> : <FaEye />}
          </div>
        </div>

        <button
          onClick={handleLogin}
          className="px-6 py-2 text-white bg-indigo-600 rounded-lg w-full hover:bg-indigo-700 transition-all duration-300 focus:outline-none focus:ring-2 focus:ring-indigo-500"
        >
          <FaKey className="inline mr-2" /> Login
        </button>

        <p className="text-gray-300 text-md mt-4">
          Don't have an account?{' '}
          <Link to="/signup" className="text-indigo-400 hover:underline">
            Sign Up
          </Link>
        </p>

        {/* Forgot Password link */}
        <p className="text-gray-300 text-md mt-1">
          Forgot your password?{' '}
          <span
            onClick={() => setShowResetModal(true)}
            className="text-indigo-400 hover:underline cursor-pointer"
          >
            Reset Password
          </span>
        </p>
      </div>

      {/* Reset Password Modal */}
      {showResetModal && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-30">
          <div className="bg-gray-800 p-6 rounded-lg shadow-xl w-full max-w-md">
            <h2 className="text-2xl text-indigo-400 mb-4">Reset Password</h2>
            <p className="text-gray-300 mb-4">Enter your email to receive reset instructions.</p>
            <input
              type="email"
              value={resetEmail}
              onChange={(e) => setResetEmail(e.target.value)}
              placeholder="Enter Email"
              className="pl-3 pr-3 py-2 text-lg rounded w-full border border-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-500 mb-4"
            />
            <button
              onClick={handlePasswordReset}
              className="px-4 py-2 bg-indigo-600 text-white rounded-lg w-full hover:bg-indigo-700 transition-all duration-300 focus:outline-none"
            >
              Send Reset Link
            </button>
            <button
              onClick={() => setShowResetModal(false)}
              className="mt-4 px-4 py-2 text-gray-400 hover:text-white transition-all duration-300 focus:outline-none"
            >
              Cancel
            </button>
          </div>
        </div>
      )}

    </div>
  );
}
