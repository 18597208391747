import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';

function AlertBox({ message, onClose }) {
  return (
    <div className="fixed inset-0 flex items-center justify-center z-50 bg-gray-900 bg-opacity-75">
      <div className="bg-gray-800 border border-indigo-500 rounded-lg p-5 text-center shadow-lg w-[80%] sm:w-[50%]">
        <p className="text-white text-lg">{message}</p>
        <button
          onClick={onClose}
          className="mt-4 px-4 py-2 bg-indigo-600 text-white rounded-lg hover:bg-indigo-700 focus:outline-none"
        >
          Close
        </button>
      </div>
    </div>
  );
}


const Project = ({ user_id }) => {
  const [projects, setProjects] = useState([]);
  const [alertMessage, setAlertMessage] = useState(''); // For AlertBox messages
  const [error, setError] = useState('');
  const [selectedProject, setSelectedProject] = useState(null);
  const [currentPage, setCurrentPage] = useState(1); // State for the current page
  const projectsPerPage = 5; // Number of projects per page
  const navigate = useNavigate();
  const detailsRef = useRef(null); // Reference for scrolling to project details
  const [showAlert, setShowAlert] = useState(false);
  const [creationName, setCreationName] = useState('');
  const newCreationRef = useRef(null); // Ref for the newly added creation



  useEffect(() => {
    const fetchProjects = async () => {
      try {
        const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || ''; // fallback for local dev

        const response = await fetch(`${API_BASE_URL}/saved_projects?user_id=${user_id}`, {
          method: 'GET',  // Typically GET request for fetching data
          headers: {
            'Content-Type': 'application/json',
          },
        });
        const data = await response.json();

        if (data.status === 'success') {
          setProjects(data.data);
        } else {
          setError('Failed to fetch projects');
        }
      } catch (err) {
        console.error('Error fetching projects:', err);
        setError('An error occurred while fetching the projects.');
      }
    };

    fetchProjects();
  }, [user_id]);

  const fetchProjectDetails = async (project_id) => {
    try {
      const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || '';

      const response = await fetch(`${API_BASE_URL}/project_details?user_id=${user_id}&project_id=${project_id}`, {
        method: 'GET',  // You can change to 'POST' or another method based on your API
        headers: {
          'Content-Type': 'application/json',
        },
      });
      const data = await response.json();

      if (data.status === 'success') {
        setSelectedProject(data.data.project);

        // Scroll to project details if detailsRef is defined
        if (detailsRef.current) {
          detailsRef.current.scrollIntoView({ behavior: 'smooth' });
        }
      } else {
        setError('Failed to fetch project details');
      }
    } catch (err) {
      console.error('Error fetching project details:', err);
      setError('An error occurred while fetching the project details.');
    }
  };

  const handleUpdateCreation = (creation) => {
    if (selectedProject) {
      console.log("Context:", creation); // Log the context here

      navigate('/newproject2', {
        state: {
          user_id: user_id,
          creation_id: creation.creation_id,
          video_name: creation.video_name,
          image_urls: creation.image_urls,
          product_name: creation.product_name,
          video_dimensions: creation.video_dimensions,
          sector: creation.sector,
          key_instructions: creation.key_instructions,
          Goal: creation.goal,
          layover_text: creation.layover_text,
          consumer_message: creation.consumer_message,
          Tags: creation.tags,
          context: creation.context,
          prompt_text: creation.prompt_text, // Existing field
          is_physical_product: creation.is_physical_product,
          duration: creation.duration, // New field added
          project_id: selectedProject.project_id, // Pass the selected project's ID

        },
      });
    }
  };
  const handleCreateNewCreation = () => {
    if (selectedProject) {
      setShowAlert(true); // Show the AlertBox
    } else {
      setError('Please select a project before creating a new creation.');
    }
  };

  const handleAlertConfirm = async () => {
    if (creationName.trim()) {
      try {
        const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || '';
        const response = await fetch(`${API_BASE_URL}/create_project_creation/`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            user_id: user_id,
            project_Id: selectedProject.project_id,
            creation_name: creationName,
          }),
        });

        const data = await response.json();

        if (data.status === 'success') {
          setAlertMessage('Creation created successfully!');

          // Refetch the project details after successful creation
          setShowAlert(false);
          setCreationName('');
          await fetchProjectDetails(selectedProject.project_id); // Fetch project details again

          // Scroll to the newly added creation (after refetch)
          if (newCreationRef.current) {
            newCreationRef.current.scrollIntoView({ behavior: 'smooth' });
          }
        } else {
          setAlertMessage(data.message || 'Failed to create creation.');
        }
      } catch (err) {
        console.error('Error creating creation:', err);
        setAlertMessage('An error occurred while creating the creation.');
      }
    } else {
      setAlertMessage('Please enter a valid creation name.');
    }
  };



  // Pagination logic
  const indexOfLastProject = currentPage * projectsPerPage;
  const indexOfFirstProject = indexOfLastProject - projectsPerPage;
  const currentProjects = projects.slice(indexOfFirstProject, indexOfLastProject);

  const totalPages = Math.ceil(projects.length / projectsPerPage);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  return (
    <div className="p-5 bg-transparent min-h-screen z-10 overflow-auto scrollbar-hide">
      <h1 className="text-3xl font-bold text-center text-gray-300 mb-6">Projects/Campaigns</h1>
      {alertMessage && <AlertBox message={alertMessage} onClose={() => setAlertMessage('')} />}

      {showAlert && (
        <div className="fixed inset-0 flex items-center justify-center z-50 bg-gray-950 bg-opacity-70 backdrop-blur-md">
          <div className="bg-gray-900 border border-gray-700 rounded-lg p-6 text-center shadow-2xl w-[90%] sm:w-[50%] lg:w-[40%]">
            <h2 className="text-indigo-400 text-xl font-bold mb-4">Enter Creation Name</h2>

            {/* Display the project name (non-editable) */}
            {selectedProject && (
              <p className="text-gray-300 mb-4 text-left text-xl">
                <span className="font-semibold">Project Name:</span> {selectedProject.project_name}
              </p>
            )}

            <input
              type="text"
              value={creationName}
              onChange={(e) => setCreationName(e.target.value)}
              placeholder="Creation Name"
              className="w-full px-4 py-2 rounded-md text-gray-200 bg-gray-800 border border-gray-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 mb-4"
            />

            <div className="flex justify-center gap-4">
              <button
                onClick={handleAlertConfirm}
                className="px-5 py-2 bg-indigo-500 text-white rounded-md hover:bg-indigo-500 transition-all"
              >
                Confirm
              </button>
              <button
                onClick={() => setShowAlert(false)}
                className="px-5 py-2 bg-red-600 text-white rounded-md hover:bg-red-500 transition-all"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}


      {error && <p className="text-red-500 text-center">{error}</p>}

      <div className="bg-gray-100 shadow-md rounded-lg p-6 bg-transparent text-gray-300 border border-gray-600">
        {currentProjects.length > 0 ? (
          <>
            <table className="w-full text-left bg-transparent">
              <thead>
                <tr>
                  <th className="py-3 px-4 border-b border-gray-600 text-gray-300 font-semibold">Project ID</th>
                  <th className="py-3 px-4 border-b border-gray-600 text-gray-300 font-semibold">Project Name/<br />Campaign Name</th>
                  <th className="py-3 px-4 border-b border-gray-600 text-gray-300 font-semibold">Created At</th>
                  <th className="py-3 px-4 border-b border-gray-600 text-gray-300 font-semibold">Updated At</th>
                  <th className="py-3 px-4 border-b border-gray-600 text-gray-300 font-semibold">Details</th>
                </tr>
              </thead>
              <tbody>
                {currentProjects.map((project) => (
                  <tr key={project.project_id} className="hover:bg-gray-700">
                    <td className="py-3 px-4 border-b border-gray-600">{project.project_id}</td>
                    <td className="py-3 px-4 border-b border-gray-600">{project.project_name}</td>
                    <td className="py-3 px-4 border-b border-gray-600">{project.created_at}</td>
                    <td className="py-3 px-4 border-b border-gray-600">{project.updated_at}</td>
                    <td className="py-3 px-4 border-b border-gray-600">
                      <button
                        onClick={() => fetchProjectDetails(project.project_id)}
                        className="text-blue-500 hover:underline"
                      >
                        Show Details
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>

            {/* Pagination controls */}
            <div className="mt-4 flex justify-center">
              {Array.from({ length: totalPages }, (_, index) => (
                <button
                  key={index}
                  onClick={() => handlePageChange(index + 1)}
                  className={`px-4 py-2 mx-1 border rounded ${currentPage === index + 1
                    ? 'bg-blue-500 text-white'
                    : 'bg-gray-700 text-gray-300 hover:bg-gray-600'
                    }`}
                >
                  {index + 1}
                </button>
              ))}
            </div>
          </>
        ) : (
          <p className="text-center text-gray-300">No projects found.</p>
        )}
      </div>

      {/* Project Details Section */}
      <div
        ref={detailsRef}
        className="mt-6 p-6 bg-gray-900 rounded-lg shadow-lg text-gray-300 border border-gray-700 overflow-auto scrollbar-hide"
        style={{
          overflow: 'auto',
        }}
      >
        {selectedProject ? (
          <> {/* Apply bg-black here */}
            <div className="flex justify-between items-center mb-6">
              <h2 className="text-3xl font-bold text-indigo-400 ">Project Details</h2>
              <button
                onClick={() => handleCreateNewCreation()}
                className="px-4 py-2 bg-green-600 text-white rounded-md hover:bg-green-500 transition"
              >
                Add New Creation
              </button>
            </div>


            <div className="space-y-4">
              <p>
                <span className="font-semibold text-gray-400">Project ID:</span> {selectedProject.project_id}
              </p>
              <p>
                <span className="font-semibold text-gray-400">Campaign Name:</span> {selectedProject.project_name}
              </p>
              <p>
                <span className="font-semibold text-gray-400">Owner Username:</span> {selectedProject.owner.username}
              </p>
              <p>
                <span className="font-semibold text-gray-400">Owner Email:</span> {selectedProject.owner.email}
              </p>
              <p>
                <span className="font-semibold text-gray-400">Created At:</span> {selectedProject.created_at}
              </p>
              <p>
                <span className="font-semibold text-gray-400">Updated At:</span> {selectedProject.updated_at}
              </p>
            </div>

            <h3 className="text-3xl font-bold mt-6 mb-4 text-indigo-400 text-center">Creations</h3>

            {selectedProject.creations.map((creation, index) => (
              <div
                key={creation.creation_id}
                ref={index === selectedProject.creations.length - 1 ? newCreationRef : null} // Assign ref to the last creation

                className="mt-4 p-4 bg-gray-800 rounded-lg border border-gray-400"
              >
                <p>
                  <span className="font-semibold text-lg text-gray-300">Creation ID:</span> {creation.creation_id}
                </p>
                <p>
                  <span className="font-semibold text-lg text-gray-300">Creation Name:</span> {creation.video_name}
                </p>
                <p>
                  <span className="font-semibold text-lg text-gray-300">Status:</span> {creation.status}
                </p>
                <p>
                  <span className="font-semibold  text-lg text-gray-300">Goal:</span> {creation.goal}
                </p>
                <p>
                  <span className="font-semibold text-lg text-gray-300">Sector:</span> {creation.sector}
                </p>
                <p>
                  <span className="font-semibold  text-lg text-gray-300">Context:</span> {creation.context}
                </p>
                <p>
                  <span className="font-semibold  text-lg text-gray-300">Tags:</span> {creation.tags.join(', ')}
                </p>
                <p>
                  <span className="font-semibold text-lg text-gray-300">Duration:</span> {creation.duration}
                </p>
                <p>
                  <span className="font-semibold  text-lg text-gray-300">Prompt Text:</span> {creation.prompt_text}
                </p>
                <div className="mt-4 flex justify-end">
                  <button
                    onClick={() => handleUpdateCreation(creation)}
                    className="px-4 py-2 bg-indigo-600 text-white rounded-md hover:bg-indigo-500 transition"
                  >
                    Update Creation
                  </button>
                </div>
              </div>
            ))}

            <div className="mt-6 flex justify-end">
              <button
                onClick={() => setSelectedProject(null)}
                className="px-4 py-2 bg-red-600 text-white rounded-md hover:bg-red-500 transition"
              >
                Close
              </button>
            </div>
          </>
        ) : (
          <p className="text-gray-400 h-[40vh] flex items-center justify-center">
            Select a project to view its details and update the creation.
          </p>
        )}
      </div>

    </div>
  );
};

export default Project;
