// AccountInfo.js
//sudo tail -f /var/log/apache2/hdb_api_error.log
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const AccountInfo = ({ user_id }) => {
  const [accountInfo, setAccountInfo] = useState(null);
  const [error, setError] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    const fetchAccountInfo = async () => {
      try {
        const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || '';
        const response = await fetch(`${API_BASE_URL}/my_account_info?user_id=${user_id}`);
        const data = await response.json();
        if (data.status === 'success') {
          setAccountInfo(data.data);
        } else {
          setError(data.message || 'Failed to fetch account information.');
        }
      } catch (error) {
        console.error('Error fetching account info:', error);
        setError('An error occurred while fetching the account information.');
      }
    };

    if (user_id) {
      fetchAccountInfo();
    }
  }, [user_id]);

  return (
    <div className="p-5 bg-transparent min-h-[70vh] flex flex-col items-center justify-center text-gray-300 z-10 ">
      <h1 className="text-3xl font-bold text-center text-gray-300 mb-6">Account Information</h1>

      {error && <p className="text-red-500 text-center mb-4">{error}</p>}

      <div className="bg-transparent border border-gray-600 shadow-md rounded-lg p-3 w-full max-w-lg text-gray-300">
        {accountInfo ? (
          <div className="space-y-1">
            <div className="flex justify-between border-b border-gray-600 py-2">
              <span className="font-semibold">Username:</span>
              <span>{accountInfo.username}</span>
            </div>
            <div className="flex justify-between border-b border-gray-600 py-2">
              <span className="font-semibold">Email:</span>
              <span>{accountInfo.email}</span>
            </div>
            <div className="flex justify-between border-b border-gray-600 py-2">
              <span className="font-semibold">Email Verified:</span>
              <span>{accountInfo.email_verification ? 'Yes' : 'No'}</span>
            </div>

            <div className="flex justify-between border-b border-gray-600 py-2">
              <span className="font-semibold">Role:</span>
              <span>{accountInfo.roles.join(', ')}</span>
            </div>
            <div className="flex justify-between border-b border-gray-600 py-2">
              <span className="font-semibold">User ID:</span>
              <span>{accountInfo.user_id}</span>
            </div>
            <div className="flex justify-between border-b border-gray-600 py-2">
              <span className="font-semibold">Credits:</span>
              <span>{accountInfo.balance !== null ? `$${accountInfo.balance}` : 'N/A'}</span>
            </div>
            <div className="flex justify-between border-b border-gray-600 py-2">
              <span className="font-semibold">Last Topped Up:</span>
              <span>{accountInfo.last_topped_up || 'N/A'}</span>
            </div>
            <div className="flex justify-between py-2">
              <span className="font-semibold">Number of Projects:</span>
              <span>{accountInfo.number_of_projects}</span>
            </div>
          </div>
        ) : (
          <p className="text-center">Loading...</p>
        )}
      </div>

      <button
        onClick={() => navigate(-1)}
        className="mt-6 px-6 py-2 bg-indigo-600 text-white rounded-lg hover:bg-indigo-500 transition-all"
      >
        Back
      </button>
    </div>
  );
};

export default AccountInfo;
