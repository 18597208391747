import React, { useState } from 'react';
import { FaInfoCircle } from 'react-icons/fa';

function AlertBox({ message, onClose }) {
  return (
    <div className="fixed inset-0 flex items-center justify-center z-50 bg-gray-900 bg-opacity-75">
      <div className="bg-gray-800 border border-indigo-500 rounded-lg p-5 text-center shadow-lg w-[80%] sm:w-[50%]">
        <p className="text-white text-lg">{message}</p>
        <button
          onClick={onClose}
          className="mt-4 px-4 py-2 bg-indigo-600 text-white rounded-lg hover:bg-indigo-700 focus:outline-none"
        >
          Close
        </button>
      </div>
    </div>
  );
}

export default function AddCredits() {
  const [credits, setCredits] = useState(0);
  const [alertMessage, setAlertMessage] = useState('');
  const [showInfo, setShowInfo] = useState(false); // State to toggle the informational text
  const ratePerCredit = 0.8; // 1 credit = 0.8 USD
  const usdToInr = 84; // Conversion rate from USD to INR
  const totalCostUsd = (credits * ratePerCredit).toFixed(2); // Calculate total cost in USD
  const totalCostInr = (credits * ratePerCredit * usdToInr).toFixed(0); // Calculate total cost in INR

  const handleSubmit = (e) => {
    e.preventDefault();
    if (credits > 0) {
      setAlertMessage(`You are purchasing ${credits} credits for $${totalCostUsd} (${totalCostInr} INR)`);
    } else {
      setAlertMessage('Please enter a valid number of credits');
    }
  };

  return (
    <div className={`flex flex-col items-center justify-center ${showInfo ? 'h-[120vh]' : 'h-[80vh]'} bg-transparent text-gray-300`}>
      {/* Display AlertBox */}
      {alertMessage && (
        <AlertBox message={alertMessage} onClose={() => setAlertMessage('')} />
      )}

      {/* Page Title */}
      <h2 className="text-4xl font-bold text-white mt-3 mb-3">Add Credits</h2>

      {/* Info Section with Toggle */}
      <div className="flex items-center justify-center mb-6">
        <button
          onClick={() => setShowInfo(!showInfo)}
          className="flex items-center gap-2 text-lg font-medium text-indigo-400 hover:text-indigo-500"
        >
          <FaInfoCircle className="text-2xl" />
          <span>How are credits consumed?</span>
        </button>
      </div>

      {/* Toggleable Informational Section */}
      {showInfo && (
        <div className="mb-8 bg-gray-800 p-6 rounded-lg shadow-md w-full max-w-3xl text-center ">
          <p className="text-gray-300 text-lg leading-relaxed">
            Credits are consumed based on the duration of video seconds generated. 
            Based on the number of tokens, words, product details, and images given to our models, 
            our system calculates the number of credits consumed. 
            On average, a <span className="font-semibold text-indigo-400">10-second video</span> consumes 
            approximately <span className="font-semibold text-indigo-400">1 Credit</span>.
          </p>
        </div>
      )}

      {/* Form Section */}
      <form
        onSubmit={handleSubmit}
        className="bg-gray-800 p-6 rounded-lg shadow-lg w-full max-w-lg mb-6"
      >
        {/* Credits Input */}
        <div className="mb-4">
          <label htmlFor="credits" className="block text-lg font-medium text-gray-300 mb-2">
            Enter Credits
          </label>
          <input
            id="credits"
            type="number"
            min="0"
            value={credits}
            onChange={(e) => setCredits(Number(e.target.value))}
            className="w-full px-4 py-2 rounded-lg bg-gray-700 text-white focus:ring focus:ring-indigo-400 focus:outline-none"
            placeholder="Enter number of credits"
            required
          />
        </div>

        {/* Display Total Cost in USD and INR Separately */}
        <div className="mb-6 text-lg font-medium text-gray-300">
          <p>
            <span className="text-indigo-400">Cost in USD:</span> ${totalCostUsd}
          </p>
          <p>
            <span className="text-indigo-400">Cost in INR:</span> ₹{totalCostInr}
          </p>
        </div>

        {/* Submit Button */}
        <button
          type="submit"
          className="w-full bg-indigo-500 text-white py-2 rounded-lg text-lg font-semibold transition-all duration-300 hover:bg-indigo-600"
        >
          Purchase Credits
        </button>
      </form>
    </div>
  );
}
