import React, { useState, useEffect } from 'react';

const Videos = ({ user_id }) => {
  const [videos, setVideos] = useState([]);
  const [error, setError] = useState('');
  const [pagination, setPagination] = useState({});
  const [currentPage, setCurrentPage] = useState(1); // Track the current page

  useEffect(() => {
    const fetchVideos = async () => {
      try {
        const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || '';  // Fallback for local development

        const response = await fetch(`${API_BASE_URL}/videos_page?user_id=${user_id}&page=${currentPage}`, {
          method: 'GET',  // GET request to fetch data
          headers: {
            'Content-Type': 'application/json',
          },
        });
        const data = await response.json();

        if (data.status === 'success') {
          setVideos(data.data); // Set the videos data
          setPagination(data.pagination); // Set pagination data
        } else {
          setError(data.message || 'Failed to fetch videos');
        }
      } catch (err) {
        console.error('Error fetching videos:', err);
        setError('An error occurred while fetching the videos.');
      }
    };

    fetchVideos();
  }, [user_id, currentPage]);

  const goToPreviousPage = () => {
    if (currentPage > 1) setCurrentPage(currentPage - 1);
  };

  const goToNextPage = () => {
    if (currentPage < pagination.pages) setCurrentPage(currentPage + 1);
  };

  return (
    <div className="p-5 bg-transparent min-h-screen z-10">
      <h1 className="text-3xl font-bold text-center text-gray-300 mb-6">Videos</h1>

      {error && <p className="text-red-500 text-center">{error}</p>}

      <div className="bg-gray-100 shadow-md rounded-lg p-3 bg-transparent text-gray-300 border border-gray-600">
        {videos.length > 0 ? (
          <table className="w-full text-left bg-transparent">
          <thead>
            <tr>
              <th className="py-1 px-2 border-b border-gray-600 text-gray-300 font-semibold">Creation ID</th>
              <th className="py-1 px-2 border-b border-gray-600 text-gray-300 font-semibold">Video Name</th>
              <th className="py-1 px-2 border-b border-gray-600 text-gray-300 font-semibold">Product Name</th>
              <th className="py-1 px-2 border-b border-gray-600 text-gray-300 font-semibold">Dimensions</th>
              <th className="py-1 px-2 border-b border-gray-600 text-gray-300 font-semibold">Status</th>
              <th className="py-1 px-2 border-b border-gray-600 text-gray-300 font-semibold">Clean Video URL</th>
              <th className="py-1 px-2 border-b border-gray-600 text-gray-300 font-semibold">Processed Video URL</th>    
              <th className="py-1 px-2 border-b border-gray-600 text-gray-300 font-semibold">Premium Layered Video</th>

              <th className="py-1 px-2 border-b border-gray-600 text-gray-300 font-semibold">Created At</th>
            </tr>
          </thead>
          <tbody>
            {videos.map((video) => (
              <tr key={video.creation_id} className="hover:bg-gray-700">
                <td className="py-1 px-2 border-b border-gray-600">{video.creation_id}</td>
                <td className="py-1 px-2 border-b border-gray-600">{video.video_name}</td>
                <td className="py-1 px-2 border-b border-gray-600">{video.product_name || 'N/A'}</td>
                <td className="py-1 px-2 border-b border-gray-600">{video.video_dimensions || 'N/A'}</td>
                <td className="py-1 px-2 border-b border-gray-600">{video.status}</td>
                <td className="py-1 px-2 border-b border-gray-600">
                  {video.cloudinary_video_url ? (
                    <>
                      <a
                        href={video.cloudinary_video_url}
                        className="text-blue-500 hover:underline"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        View 
                      </a>{' '}
                      <button
                        onClick={async () => {
                          try {
                            const response = await fetch(video.cloudinary_video_url);
                            const blob = await response.blob();
                            const url = window.URL.createObjectURL(blob);
                            const a = document.createElement('a');
                            a.href = url;
                            a.download = `cloudinary_video_${video.creation_id}.mp4`;
                            a.click();
                            window.URL.revokeObjectURL(url);
                          } catch (error) {
                            console.error('Error downloading video:', error);
                          }
                        }}
                        className="mt-1 text-blue-500 hover:underline block" // Adds spacing and ensures a new line
                        >Download
                      </button>
                    </>
                  ) : (
                    'No URL'
                  )}
                </td>
                <td className="py-1 px-2 border-b border-gray-600">
                  {video.processed_video_url ? (
                    <>
                      <a
                        href={video.processed_video_url}
                        className="text-blue-500 hover:underline"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        View
                      </a>
            
                      <button
                        onClick={async () => {
                          try {
                            const response = await fetch(video.processed_video_url);
                            const blob = await response.blob();
                            const url = window.URL.createObjectURL(blob);
                            const a = document.createElement('a');
                            a.href = url;
                            a.download = `processed_video_${video.creation_id}.mp4`;
                            a.click();
                            window.URL.revokeObjectURL(url);
                          } catch (error) {
                            console.error('Error downloading video:', error);
                          }
                        }}
                        className="mt-1 text-blue-500 hover:underline block" // Adds spacing and ensures a new line
                        >Download
                      </button>
                    </>
                  ) : (
                    'No URL'
                  )}
                </td>
                <td className="py-1 px-2 border-b border-gray-600">
                  {video.manual_video_url ? (
                    <>
                      <a
                        href={video.manual_video_url}
                        className="text-blue-500 hover:underline"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        View
                      </a>{' '}
                      /{' '}
                      <button
                        onClick={async () => {
                          try {
                            const response = await fetch(video.manual_video_url);
                            const blob = await response.blob();
                            const url = window.URL.createObjectURL(blob);
                            const a = document.createElement('a');
                            a.href = url;
                            a.download = `manual_video_${video.creation_id}.mp4`;
                            a.click();
                            window.URL.revokeObjectURL(url);
                          } catch (error) {
                            console.error('Error downloading video:', error);
                          }
                        }}
                        className="ml-2 text-blue-500 hover:underline"
                      >
                        Download
                      </button>
                    </>
                  ) : (
                    'No URL'
                  )}
                </td>
                <td className="py-1 px-2 border-b border-gray-600">
                  {video.created_at}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        
        ) : (
          <p className="text-center text-gray-300">No videos found.</p>
        )}
      </div>

      {/* Pagination Controls */}
      {pagination && (
        <div className="flex justify-center items-center mt-6 text-gray-300 space-x-4">
          <button
            onClick={goToPreviousPage}
            disabled={currentPage === 1}
            className={`px-4 py-2 rounded ${currentPage === 1 ? 'opacity-50 cursor-not-allowed' : 'hover:bg-gray-600'}`}
          >
            &larr; Previous
          </button>
          <p>Page {pagination.page} of {pagination.pages}</p>
          <button
            onClick={goToNextPage}
            disabled={currentPage === pagination.pages}
            className={`px-4 py-2 rounded ${currentPage === pagination.pages ? 'opacity-50 cursor-not-allowed' : 'hover:bg-gray-600'}`}
          >
            Next &rarr;
          </button>
        </div>
      )}
    </div>
  );
};

export default Videos;
