import React, { useState, useRef, useEffect } from 'react';
import { Link, Routes, Route, useLocation, useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';
import { FaAngleLeft, FaAngleRight } from 'react-icons/fa';
import Logo from './Logo';
import NewProject from './NewProject';
import Assets from './Assets';
import SignUp from './SignUp';
import GenerateKey from './GenerateKey';
import Project from './Project';
import Videos from './videos';
import NewProject2 from './newproject2';
import AccountInfo from './Acountinfo';
import AddCredits from "./credits";
import ExecuteVideo from './Executevideo';
import { FaHome, FaProjectDiagram, FaVideo, FaKey, FaUser, FaCogs } from 'react-icons/fa';
import { MdAddBox } from 'react-icons/md';
function AlertBox({ message, onClose }) {
  return (
    <div className="fixed inset-0 flex items-center justify-center z-50 bg-gray-900 bg-opacity-75">
      <div className="bg-gray-800 border border-indigo-500 rounded-lg p-5 text-center shadow-lg w-[80%] sm:w-[50%]">
        <p className="text-white text-lg">{message}</p>
        <button
          onClick={onClose}
          className="mt-4 px-4 py-2 bg-indigo-600 text-white rounded-lg hover:bg-indigo-700 focus:outline-none"
        >
          Close
        </button>
      </div>
    </div>
  );
}

export default function Dashboard({ username, setUser }) {
  const [isPanelVisible, setIsPanelVisible] = useState(true);
  const [hoveredIndex, setHoveredIndex] = useState(null);
  const [dashboardData, setDashboardData] = useState({
    total_projects: 0,
    total_videos: 0,
    total_assets: 0,
    total_keys: 0,
    emailVerification: false, // Default value set to false

  });
  const [dropdownVisible, setDropdownVisible] = useState(false); // State to toggle dropdown visibility
  const dropdownRef = useRef(null); // Create a ref for the dropdown

  const navigate = useNavigate();
  const location = useLocation();
  const [alertMessage, setAlertMessage] = useState('');

  const user_id = localStorage.getItem('user_id');
  const email = localStorage.getItem('email'); // Assuming email is stored in localStorage

  // const isEmailVerified = true; 


  const menuOptions = [
    { name: 'Home', path: '/', icon: <FaHome /> },
    { name: 'New Project/Campaign', path: '/newproject', icon: <MdAddBox /> },
    { name: 'Project/Campaign', path: '/project', icon: <FaProjectDiagram /> },
    { name: 'Assets', path: '/assets', icon: <FaCogs /> },
    { name: 'Videos', path: '/videos', icon: <FaVideo /> },
    { name: 'Generate Key', path: '/generatekey', icon: <FaKey /> },
    { name: 'Roles', path: '/roles', icon: <FaUser /> },
  ];


  useEffect(() => {
    const fetchDashboardData = async () => {
      try {
        const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || '';
        const response = await fetch(`${API_BASE_URL}/dashboard?user_id=${user_id}`);
        const data = await response.json();

        console.log('Full API Response:', data); // Log the full response
        console.log('Dashboard Data Object:', data.data); // Log the data object

        if (data.status === 'success') {
          // Correctly access 'email_verification' from the response
          const emailVerification = data.data.email_verification; // Use the correct key

          console.log('Email Verification:', emailVerification); // Log resolved value

          setDashboardData({
            ...data.data,
            emailVerification: emailVerification !== undefined ? emailVerification : false, // Ensure default value
          });
        } else {
          console.error('Error fetching dashboard data:', data.message);
        }
      } catch (error) {
        console.error('Error fetching dashboard data:', error);
      }
    };

    if (user_id) {
      console.log('User ID:', user_id); // Log the user ID
      fetchDashboardData();
    }
  }, [user_id]);


  const togglePanel = () => {
    setIsPanelVisible((prev) => !prev);
  };

  const handleLogout = () => {
    localStorage.removeItem('username');
    setUser(null);
    navigate('/login');
  };

  const handleDPClick = () => {
    setDropdownVisible((prev) => !prev); // Toggle dropdown visibility on DP click
  };
  const handleClickOutside = (event) => {
    // Close dropdown if click is outside the dropdown
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setDropdownVisible(false);
    }
  };

  useEffect(() => {
    // Attach click event listener to document
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Cleanup event listener on component unmount
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  const handleAccountInfoClick = () => {
    navigate('/accountinfo'); // Navigate to Account Info
    setDropdownVisible(false); // Close dropdown after clicking
  };

  const handleSignOutClick = () => {
    handleLogout(); // Perform signout
    setDropdownVisible(false); // Close dropdown after clicking
  };

  const handleVerifyNow = async () => {
    try {
      const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || '';
      const response = await fetch(`${API_BASE_URL}/email_verification_request/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email }),
      });
      const data = await response.json();
      if (data.status === 'success') {
        setAlertMessage(
          <>
            A new email verification email has been sent to your registered email ID
          </>
        );
        // setAlertMessage(data.message); // Show success message
        setDashboardData((prevData) => ({
          ...prevData,
          emailVerification: true, // Update state to reflect the email is verified
        }));
      } else {
        setAlertMessage('Failed to send verification link.');
      }
    } catch (error) {
      console.error('Error sending verification request:', error);
      alert('An error occurred. Please try again.');
    }
  };


  return (
    <div className="flex h-screen bg-gradient-to-b from-gray-900 to-gray-900 overflow-hidden z-50">
      {alertMessage && (
        <AlertBox message={alertMessage} onClose={() => setAlertMessage('')} />
      )}
      {/* Header */}
      <header className="fixed top-0 w-full p-1 md:p-0 bg-gray-900 bg-opacity-30 border-b border-gray-600 z-0 flex items-center justify-between ">
        <Logo />
        {!dashboardData.emailVerification && (
          <div
            className="flex items-center gap-3 bg-gradient-to-r from-indigo-600 to-indigo-500 text-white px-2 py-1 rounded-lg shadow-lg"
          >
            <span className="font-italic text-sm md:text-base">Please verify your email within 72 hours!</span>
            <button
              onClick={handleVerifyNow}
              className="bg-white text-indigo-600 font-semibold px-2 py-1 rounded-md hover:bg-indigo-200 transition duration-300 ease-in-out"
            >
              Verify Now
            </button>
          </div>
        )}


        {/* User Info Section */}
        <div className="flex items-center gap-4 sm:gap-9">
          <span className="text-gray-300 text-2xl  ">
            <p>
              Credits:
              {dashboardData && dashboardData.balance !== null
                ? `${dashboardData.balance}`
                : ' N/A'}
            </p>
          </span>

          <span className="text-gray-300 text-2xl ">
            {username
              .split(' ')
              .map(word => word.charAt(0).toUpperCase() + word.slice(1))
              .join(' ')}
          </span>

          {/* Profile Picture (DP) */}
          <div
            className="w-12 h-12 flex items-center justify-center rounded-full bg-gray-700 text-white font-semibold text-xl cursor-pointer mr-5"
            onClick={handleDPClick} // Toggle dropdown on click
          >
            DP
          </div>


        </div>

        {/* Dropdown Menu */}
        {/* Dropdown Menu */}
        {dropdownVisible && (
          <div
            ref={dropdownRef} // Attach ref to the dropdown container

            className="absolute top-14 right-4 bg-gray-800 text-gray-300 p-0 rounded-lg shadow-lg w-40 z-70">
            <button
              onClick={handleAccountInfoClick}
              className="w-full text-left p-2 hover:bg-gray-700 rounded mb-2"
            >
              Account Info
            </button>
            <button
              onClick={() => {
                navigate('/credits'); // Navigate to /credits
                setDropdownVisible(false); // Close dropdown
              }}
              className="w-full text-left p-2 hover:bg-gray-700 rounded mb-2"
            >
              Add Credits
            </button>
            <button
              onClick={handleSignOutClick}
              className="w-full text-left p-2 hover:bg-gray-700 rounded mb-2 z-70"
            >
              Sign Out
            </button>
          </div>
        )}

      </header>

      <div className="flex flex-1 mt-[80px]">
        {/* Left Panel */}
        <motion.div
          initial={{ width: '250px' }} // Panel starts fully expanded
          animate={{ width: isPanelVisible ? '250px' : '60px' }} // Expands or collapses based on `isPanelVisible`
          transition={{ type: 'spring', stiffness: 300, damping: 30 }}
          className="p-3 bg-transparent text-gray-300 flex flex-col justify-between shadow-md z-10 "
        >
          {/* Menu Items */}
          <div className="flex flex-col gap-4 mt-2 w-full text-left flex-grow ">
            {menuOptions.map(({ name, path, icon }) => (
              <Link
                key={name}
                to={path}
                className={`flex items-center text-gray-300 bg-transparent p-1 rounded text-lg transition-all duration-300 hover:bg-gray-700 hover:text-indigo-400 ${isPanelVisible ? 'justify-start' : 'justify-center'
                  }`}
              >
                <span className="text-xl">{icon}</span>
                {isPanelVisible && <span className="ml-3">{name}</span>}
              </Link>
            ))}
          </div>

          {/* Buttons at the bottom of the left panel */}
          <div className="flex justify-between items-center mt-auto">
            {/* Logout Button */}
            <button
              onClick={handleLogout}
              className={`text-gray-300 bg-transparent p-1 rounded text-xl transition-all duration-300 hover:bg-gray-700 hover:text-indigo-400 ${isPanelVisible ? 'w-4/5 text-left' : 'w-full text-center'
                }`}
            >
              {isPanelVisible ? 'Logout' : <FaKey size={24} />}
            </button>

            {/* Mobile Menu Toggle Button */}
            <button
              onClick={togglePanel}
              className="text-gray-300 text-xl focus:outline-none"
            >
              {isPanelVisible ? <FaAngleLeft size={30} /> : <FaAngleRight size={30} />}
            </button>
          </div>
        </motion.div>

        {/* Main Content */}
        <motion.div
          initial={{ flexBasis: 'calc(100% - 250px)' }} // Initial width when panel is expanded
          animate={{ flexBasis: isPanelVisible ? 'calc(100% - 250px)' : 'calc(100% - 60px)' }} // Adjust based on panel width
          transition={{ type: 'spring', stiffness: 300, damping: 30 }}
          className="flex-grow p-0 bg-transparent text-gray-300 overflow-y-auto mx-aut0 overflow-auto scrollbar-hide"
        >
          {/* Main content goes here */}
          {/* </motion.div> */}

          <Routes>
            <Route path="/" element={<h2 className="text-2xl font-semibold text-center mt-5 z-0">Welcome to the Dashboard of HyGaar</h2>} />
            <Route path="/newproject" element={<NewProject user_id={user_id} />} />
            <Route path="/project" element={<Project user_id={user_id} />} />
            <Route path="/newproject2" element={<NewProject2 user_id={user_id}/>} />
            <Route path="/assets" element={<Assets user_id={user_id} />} />
            <Route path="/videos" element={<Videos user_id={user_id} />} />
            <Route path="/generatekey" element={<GenerateKey user_id={user_id} />} />
            <Route path="/signup" element={<SignUp />} />
            <Route path="/accountinfo" element={<AccountInfo user_id={user_id} />} />
            <Route path="/credits" element={<AddCredits user_id={user_id} />} />
            <Route path="/executevideo" element={<ExecuteVideo user_id={user_id} />} />



            <Route
              path="/roles"
              element={
                <div className="flex flex-col items-center justify-center min-h-screen bg-gray-900 text-gray-300">
                  <h2 className="text-4xl font-bold text-white mb-4 animate-bounce">
                    Coming Soon
                  </h2>
                  <p className="text-lg text-gray-400">
                    We're working hard to bring this feature to life. Stay tuned!
                  </p>
                  <div className="mt-6">
                    <svg
                      className="w-12 h-12 text-blue-500 animate-spin"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <circle
                        className="opacity-25"
                        cx="12"
                        cy="12"
                        r="10"
                        stroke="currentColor"
                        strokeWidth="4"
                      ></circle>
                      <path
                        className="opacity-75"
                        fill="currentColor"
                        d="M4 12a8 8 0 018-8v8H4z"
                      ></path>
                    </svg>
                  </div>
                </div>
              }
            />
          </Routes>
          {location.pathname === '/' && (
            <div className="p-0 bg-transparent z-0 ">

              <section className="grid grid-cols-4 gap-1 mt-12 p-4 w-full max-w-4xl mx-auto z-0">
                {[{ name: 'Total Projects', count: dashboardData.total_projects },
                { name: 'Total Videos', count: dashboardData.total_videos },
                { name: 'Total Keys', count: dashboardData.total_keys },

                { name: 'Total Assets', count: dashboardData.total_assets }].map((item, index) => (
                  <motion.div
                    key={item.name}
                    className={`flex flex-col items-center z-0 justify-center w-40 h-40 p-3 rounded-xl bg-gray-800 shadow-md text-center transition-transform duration-300 ${hoveredIndex === index ? 'ring-2 ring-indigo-500' : ''}`}
                    initial={{ opacity: 0, y: 50 }}
                    animate={{ opacity: 1, y: 0 }}
                    onHoverStart={() => setHoveredIndex(index)}
                    onHoverEnd={() => setHoveredIndex(null)}
                    onClick={() => console.log(`${item.name}clicked`)} // Placeholder for click functionality
                  >
                    <h2 className="text-xl text-white font-semibold">{item.name}</h2>
                    <p className="text-xl text-white font-bold mt-3">{item.count}</p>
                  </motion.div>
                ))}
              </section>
            </div>
          )}
        </motion.div>
      </div>
    </div>

  );
}
