import React from "react";

export default function PageIllustration({ multiple = false }) {
  return (
    <div className="relative w-full h-auto pointer-events-none z-0"> {/* Prevent interaction with background */}
      <img
        className="max-w-[100vh] absolute top-0 right-0" // Adjust max-width responsively
        src="/images/page-illustration.svg" // Direct path to image in public folder
        width={846}
        height={594}
        alt="Page illustration"
      />
      {multiple && (
        <>
          <img
            className="max-w-[40vh] sm:max-w-[80vh] absolute top-[40%] left-[0%] sm:top-[30%] md:top-[20%] lg:top-[10%]" // Adjust top positioning based on screen size
            src="/images/blurred-shape-gray.svg" // Direct path to image in public folder
            width={760}
            height={668}
            alt="Blurred shape"
          />
          <img
            className="max-w-[60vh]  absolute top-[0%] left-[0%]" // Adjust top positioning based on screen size
            src="/images/blurred-shape.svg" // Direct path to image in public folder
            width={760}
            height={668}
            alt="Blurred shape"
          />
        </>
      )}
    </div>
  );
}
