import React, { useState, useEffect } from "react";
import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import "./css/style.css";
import PageIllustration from "./components/PageIllustration";
import Dashboard from "./components/dashboard";
import NewProject from "./components/NewProject";
import Assets from "./components/Assets";
import Login from "./components/Login";
import Signup from "./components/SignUp";
import GenerateKey from "./components/GenerateKey";
import Project from "./components/Project";
import Videos from "./components/videos";
import NewProject2 from "./components/newproject2";
import AccountInfo from "./components/Acountinfo";
import AddCredits from "./components/credits";
import ExecuteVideo from "./components/Executevideo";

export default function Home() {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const location = useLocation(); // Get the current route

  useEffect(() => {
    // Check if username exists in sessionStorage
    const storedUsername = sessionStorage.getItem("username");
    if (storedUsername) {
      setUser(storedUsername);
    }
    setLoading(false);
  }, []);

  const handleLogin = (username) => {
    sessionStorage.setItem("username", username);
    setUser(username);
  };

  const handleLogout = () => {
    sessionStorage.removeItem("username");
    setUser(null);
  };

  if (loading) {
    return <div>Loading...</div>; // Optional: Add a spinner/loader here
  }

  return (
    <>
      {/* Conditionally render PageIllustration based on the route */}
      {location.pathname !== "/project" && <PageIllustration multiple={true} />}

      {user ? (
        <Dashboard username={user} setUser={handleLogout}>
          {/* Dashboard Routes */}
          <Routes>
            <Route path="/" element={<Navigate to="/dashboard" />} />
            <Route path="/newproject" element={<NewProject />} />
            <Route path="/assets" element={<Assets />} />
            <Route path="/videos" element={<Videos />} />
            <Route path="/generatekey" element={<GenerateKey />} />
            <Route path="/project" element={<Project />} />
            <Route path="/newproject2" element={<NewProject2 />} />
            <Route path="/accountinfo" element={<AccountInfo />} />
            <Route path="/credits" element={<AddCredits />} />
            <Route path="/executevideo" element={<ExecuteVideo />} />
          </Routes>
        </Dashboard>
      ) : (
        <>
          {/* Routes for Login and Signup */}
          <Routes>
            <Route path="/" element={<Navigate to="/login" />} />
            <Route path="/login" element={<Login setUser={handleLogin} />} />
            <Route path="/signup" element={<Signup setUser={handleLogin} />} />
          </Routes>
        </>
      )}
    </>
  );
}
